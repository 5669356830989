import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import { useSwipeable } from "react-swipeable";

import Cookies from "js-cookie";

import Catalog from "./pages/Catalog/Catalog";
// import Feed from "./pages/Feed/Feed";
import Orders from "./pages/Orders/Orders";
import Dashboard from "./pages/Dashboard/Dashboard";
import Spreadsheet from "./pages/Spreadsheet/Spreadsheet";
import Integrations from "./pages/Integrations/Integrations";
import Login from "./pages/Login/Login";
import Signup from "./pages/Login/Signup";

import AdminDashboard from "./pages/Admin/Dashboard/Dashboard";
import AdminOrders from "./pages/Admin/Orders/Orders";
import AdminProducts from "./pages/Admin/AdminProducts";
import AdminUsers from "./pages/Admin/Users/Users";

import AdminGroups from "./pages/Admin/Groups/Groups";
import AdminBoxes from "./pages/Admin/Boxes/Boxes";

import NavbarLayout from "./layouts/NavbarLayout";
import AdminLayout from "./layouts/AdminLayout";

import Auth from "./util/Auth";
import AdminAuth from "./util/AdminAuth";
// import Welcome from "./components/AIstemChat/AIstemChat";
import EntrancePayment from "./pages/EntrancePayment/EntrancePayment";
import Maintenance from "./pages/Maintenance/Maintenance";
import AdminAsk from "./pages/Admin/Orders/Ask";
import AdminBurn from "./pages/Admin/Orders/Burn";
import AdminAllOrders from "./pages/Admin/Orders/AllOrders";
import RecentOrders from "./pages/Admin/RecentOrders/RecentOrders";
// import BlankPage from "./pages/Admin/BlankPage/BlankPage";
import Aistem from "./pages/Admin/Aistem/Aistem";
import AistemMessages from "./pages/Admin/Aistem/AistemMessages";
import LandingPage from "./pages/LandingPage/LandingPage";
import BlingError from "./pages/Errors/BlingError";
import Meli from "./pages/Meli/Meli";
import Pending from "./pages/Admin/Orders/Pending";
import Materials from "./pages/Admin/Materials/Materials";
import RecentMaterials from "./pages/Admin/RecentOrders/RecentMaterials";
import AdminLogin from "./pages/Admin/Login/Login";
import { useStateAndRef } from "./util/utils";
import Cancelled from "./pages/Admin/Orders/Cancelled";

import Starbox from "./pages/Admin/Starbox/Starbox";
import Magalu from "./pages/Magalu/Magalu";
import RenewBlock from "./pages/RenewBlock/RenewBlock";
import Payments from "./pages/Admin/Payments/Payments";
import Banned from "./pages/Banned/Banned";

export const UserContext = React.createContext(null);

const maintenance = false;

function zebraPrint(printer, data, type) {
    return new Promise(async (resolve, reject) => {
        switch (type) {
            case "pdf":
                printer.sendFile(
                    data,
                    (res) => {
                        console.log(res);
                        resolve("ok");
                    },
                    (err) => {
                        //alert(err);
                        console.log(err);
                        reject(err);
                    }
                );
                break;
            case "img":
                try {
                    printer.convertAndSendFile(
                        data,
                        (res) => {
                            console.log(res);
                            resolve("ok");
                        },
                        (err) => {
                            //alert(err);
                            console.log(err);
                            reject(err);
                        },
                        {
                            fitTo: {
                                width: 1200,
                                height: 1200,
                            },
                        }
                    );
                } catch (err) {
                    alert("Erro ao executar impressão! Verifique qual impressora está selecionada e tente novamente.");
                    reject(err);
                }
                break;
            default:
                try {
                    printer.send(
                        data,
                        (res) => {
                            console.log(res);
                            resolve("ok");
                        },
                        (err) => {
                            //alert(err);
                            console.log(err);
                            reject(err);
                        }
                    );
                } catch (err) {
                    alert("Erro ao executar impressão! Verifique qual impressora está selecionada e tente novamente.");
                    reject(err);
                }
                break;
        }
    });
}

function App() {
    const [navbarOpen, setNavbarOpen] = useState(false);

    const handlers = useSwipeable({
        onSwipedRight: (eventData) => setNavbarOpen(true),
        onSwipedLeft: (eventData) => setNavbarOpen(false),
        delta: 30,
        // ...config,
    });

    let oldScrollY = 0;

    const [hidden, setHidden] = useState(false);

    const controlDirection = () => {
        if (window.scrollY > oldScrollY) {
            setHidden(true);
        } else {
            setHidden(false);
        }
        oldScrollY = window.scrollY;
    };

    useEffect(() => {
        window.addEventListener("scroll", controlDirection);
        return () => {
            window.removeEventListener("scroll", controlDirection);
        };
    }, []);

    const [user, setUser] = useState({});
    const [products, setProducts] = useState(null);
    const [integrations, setIntegrations] = useState([]);
    const [orders, setOrders] = useState([]);
    const [payments, setPayments] = useState([]);
    const [messages, setMessages] = useState([]);
    const [chatting, setChatting] = useState(false);

    const [adminProducts, setAdminProducts] = useState([]);
    const [adminBoxes, setAdminBoxes] = useState([]);
    const [adminGroups, setAdminGroups] = useState([]);
    const [adminUsers, setAdminUsers] = useState([]);
    const [adminOrders, setAdminOrders] = useState([]);
    const [adminPayments, setAdminPayments] = useState([]);
    const [adminMaterials, setAdminMaterials] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [autoPrint, setAutoPrint] = useState(JSON.parse(sessionStorage.getItem("autoPrint")));

    const [plpPrinter, setPlpPrinter] = useState({});
    const [etqPrinter, setEtqPrinter] = useState({});

    const [newUser, setNewUser] = useState(null);

    const [starboxSlots, setStarboxSlots] = useState(null);
    const [starboxTasks, setStarboxTasks] = useState(null);
    const [starboxToday, setStarboxToday] = useState(null);

    const [config, setConfig] = useState(null);

    const [starboxStatus, setStarboxStatus] = useState({
        x: 0,
        y: 0,
        z: 0,
        status: "Offline",
    });

    const [notificationList, setNotificationList, notificationListRef] = useStateAndRef([]);

    const modals = useRef({});

    const [newMessage, setNewMessage] = useState(null);

    const [orders10Days, setOrders10Days] = useState(null);

    return (
        <div className="App" {...handlers}>
            <UserContext.Provider
                value={{
                    user,
                    setUser,
                    products,
                    setProducts,
                    integrations,
                    setIntegrations,
                    orders,
                    setOrders,
                    payments,
                    setPayments,
                    messages,
                    setMessages,
                    chatting,
                    setChatting,

                    setAdminProducts,
                    adminProducts,
                    setAdminBoxes,
                    adminBoxes,
                    setAdminGroups,
                    adminGroups,
                    setAdminUsers,
                    adminUsers,
                    setAdminOrders,
                    adminOrders,
                    setAdminPayments,
                    adminPayments,
                    setAdminMaterials,
                    adminMaterials,
                    setOnlineUsers,
                    onlineUsers,
                    newUser,
                    setNewUser,
                    autoPrint,
                    setAutoPrint,
                    plpPrinter,
                    setPlpPrinter,
                    etqPrinter,
                    setEtqPrinter,
                    zebraPrint,
                    modals,

                    newMessage,
                    setNewMessage,

                    hidden,
                    setHidden,

                    notificationList,
                    setNotificationList,
                    notificationListRef,

                    starboxSlots,
                    setStarboxSlots,

                    starboxTasks,
                    setStarboxTasks,

                    starboxStatus,
                    setStarboxStatus,

                    setStarboxToday,
                    starboxToday,

                    config,
                    setConfig,

                    orders10Days,
                    setOrders10Days,
                }}
            >
                <Routes>
                    {!maintenance || (maintenance && Cookies.get("maintenance") === "true") ? (
                        <>
                            <Route path="/teste" element={<LandingPage />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/cadastro" element={<Signup />} />
                            <Route path="/logout" element={<Navigate to="/login" />} />

                            <Route path="/erro-bling" element={<BlingError />} />

                            <Route
                                path="/pagamento"
                                element={
                                    <Auth>
                                        <EntrancePayment user={user} />
                                    </Auth>
                                }
                            />

                            <Route
                                path="/banido"
                                element={
                                    <Auth>
                                        <Banned user={user} />
                                    </Auth>
                                }
                            />

                            <Route
                                path="/renovacao"
                                element={
                                    <Auth>
                                        <RenewBlock user={user} />
                                    </Auth>
                                }
                            />

                            <Route path="/admin/login" element={<AdminLogin />} />

                            <Route
                                path="/admin/aistem-screen"
                                element={
                                    <AdminAuth setUser={setUser}>
                                        <AdminLayout user={user} fullscreen={true} />
                                    </AdminAuth>
                                }
                            />

                            <Route
                                path="/admin"
                                element={
                                    <AdminAuth setUser={setUser}>
                                        <AdminLayout user={user} />
                                    </AdminAuth>
                                }
                            >
                                <Route index element={<AdminDashboard user={user} />} />
                                <Route path="orders" element={<AdminOrders />} />

                                <Route path="allorders" element={<AdminAllOrders />} />

                                <Route path="solicitacoes" element={<AdminAsk />} />
                                <Route path="gravacoes" element={<AdminBurn />} />

                                <Route path="products" element={<AdminProducts />} />
                                <Route path="users" element={<AdminUsers />} />
                                <Route path="usuarios-pagamentos" element={<Payments />} />
                                <Route path="grupos" element={<AdminGroups />} />
                                <Route path="caixas" element={<AdminBoxes />} />
                                <Route path="materiais" element={<Materials />} />
                                <Route path="vendas10dias" element={<RecentOrders />} />
                                <Route path="relatorio-materiais" element={<RecentMaterials />} />
                                <Route path="coleta" element={<Pending />} />
                                <Route path="cancelamentos" element={<Cancelled />} />

                                <Route path="aistem" element={<Aistem />} />
                                <Route path="mensagens-aistem" element={<AistemMessages />} />

                                <Route path="starbox" element={<Starbox />} />
                            </Route>

                            <Route
                                element={
                                    <Auth setUser={setUser}>
                                        <NavbarLayout navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
                                    </Auth>
                                }
                            >
                                <Route index element={<Catalog user={user} setUser={setUser} />} />

                                <Route path="produtos" element={<Catalog user={user} setUser={setUser} />} />
                                <Route path="integracoes/bling" element={<Spreadsheet user={user} />} />
                                <Route path="integracoes/meli" element={<Meli />} />
                                <Route path="integracoes/magalu" element={<Magalu />} />
                                <Route path="pedidos" element={<Orders />} />
                                <Route path="dashboard" element={<Dashboard user={user} />} />
                                <Route path="integracoes" element={<Integrations user={user} setUser={setUser} />} />
                            </Route>
                        </>
                    ) : (
                        <Route path="*" element={<Maintenance />} />
                    )}
                </Routes>
            </UserContext.Provider>
        </div>
    );
}

export default App;
