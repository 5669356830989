import { useRef, useState } from "react";

function isEmpty(value) {
    return value == null || (typeof value === "string" && value.trim().length === 0);
}

function notEmpty(value) {
    return isEmpty(value) ? false : value;
}

function useStateAndRef(initial) {
    const [value, setValue] = useState(initial);
    const valueRef = useRef(value);
    valueRef.current = value;
    return [value, setValue, valueRef];
}

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function getEtq(id, sku, name, starbox) {
    return `^XA
        ^PW319
        ^FWN
        ^LH0,5
        ^FO245,120^GFA,170,170,5,,::003C0018,007E007E,00FF00FE,00FF00FF,:00FE00FE,007E007E,003C003C,,I07FFE,003JFE,00LF8,03FF80FFE,07F8001FF,0FEJ07F81FCJ03F81F8J01FC1F8K0FC:1F8J01FC1FCJ03F80FEJ07F807F8I0FF,03FF00FFE,00LF8,003JFE,I07IF,,:::^FS
        
        ^BY2
        ^FO35,8 ^BCN,100,N ^FD${(starbox ? "0" : "#") + `${id}`.padStart(7, "0")}^FS
        ^FO35,125 ^A0,30 ^FD${sku}^FS
        ^FO35,165 ^A0,20 ^FD${(name || "Produto").normalize("NFD").replace(/[\u0300-\u036f]/g, "")}^FS
        ^XZ`;
}

const DEV = false;

function onlinePrint(printer, zpl) {
    fetch("/api/print/createprint", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ printer: DEV ? 3 : printer, zpl: zpl }),
    });
}

export { isEmpty, notEmpty, useStateAndRef, sleep, getEtq, onlinePrint };
