import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";

import useWebSocket from "react-use-websocket";
import { useContext } from "react";
import { UserContext } from "../App.js";
import LandingPage from "../pages/LandingPage/LandingPage.js";

const calculateDaysDifference = (createdAt) => {
    const currentDate = new Date();
    const vencimentoDate = new Date(createdAt);
    vencimentoDate.setFullYear(vencimentoDate.getFullYear() + 1);

    // Calcula a diferença em milissegundos e converte para dias
    const timeDiff = vencimentoDate - currentDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff;
};

const isLessThanTwoMonths = (createdAt) => {
    const daysDiff = calculateDaysDifference(createdAt);
    console.log(daysDiff);
    return daysDiff < -1; // Aproximadamente 2 meses
};

const Auth = (props) => {
    const { setUser, setIntegrations, integrations, setOrders, orders, setPayments, payments, setMessages, messages, setNewMessage, setProducts, modals } =
        useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useWebSocket(
        !["orizom.com.br", "orizom.cloud"].includes(window.location.hostname)
            ? `ws://${window.location.hostname}:8080/ws`
            : `wss://${window.location.hostname}/ws`,

        {
            onMessage: (msg) => {
                try {
                    let data = JSON.parse(msg.data);
                    if (data.user) setUser(data.user);

                    if (data.products) setProducts(data.products);

                    if (data.integrations) setIntegrations(Object.fromEntries(data.integrations.map((e) => [e.id, e])));
                    if (data.orders) setOrders(Object.fromEntries(data.orders.map((e) => [e.id, e])));

                    if (data.order) {
                        setOrders({ ...orders, [data.order.id]: data.order });

                        if (modals?.current?.modalOrderObj?.id === data?.order?.id) {
                            modals.current.setModalOrderObj(data?.order);
                        }
                    }

                    if (data.integration) {
                        let newIntegrations = { ...integrations };
                        newIntegrations[data.integration.id] = data.integration;
                        if (data.integration.destroy) delete newIntegrations[data.integration.id];
                        setIntegrations(newIntegrations);
                    }

                    if (data.payment) {
                        if (!payments.find((e) => e.id === data.payment.id)) {
                            setPayments([...payments, data.payment]);
                        }
                    }

                    if (data.messages && !messages.length) {
                        setMessages(data.messages);
                    }

                    if (data.message) {
                        setNewMessage(data?.message);

                        setMessages([
                            {
                                ...data?.message,
                                ...data?.message?.data,
                                resolve: () => {
                                    return new Promise((resolve) => setTimeout(() => resolve(data.message.data.content), 1000));
                                },
                            },
                            ...messages,
                        ]);
                    }

                    if (data.error && data.error === "NOT_LOGGED_IN") {
                        if (window.location.pathname != "/") navigate(`/login?redirect=${window.location.pathname + window.location.search}`);
                        setIsLoading(false);
                    } else {
                        if (data.loggedIn) {
                            setIsLoggedIn(true);
                            setIsLoading(false);
                        }
                    }

                    if (data.error && data.error === "PAYMENT") {
                        navigate(`/pagamento`);
                        setIsLoading(false);
                    }

                    if (data?.user?.banned) {
                        navigate(`/banido`);
                        setIsLoading(false);
                    }

                    if (isLessThanTwoMonths(data.user.lastPaid || data.user.createdAt)) {
                        navigate("/renovacao");
                        setIsLoading(false);
                    }
                } catch (err) {
                    console.error(err);
                }
            },
            onError: (event) => {
                console.error(event);
            },
            shouldReconnect: (closeEvent) => true,
            reconnectInterval: 3000,
            reconnectAttempts: 50,
        }
    );

    if (!isLoading) {
        if (window.location.pathname == "/" && !isLoggedIn) return <LandingPage />;
        else return props.children;
    }

    return (
        <div className="auth-loader">
            <Loader />
        </div>
    );
};

export default Auth;
