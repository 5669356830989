import React, { useEffect, useState, useContext, useRef } from "react";
import Cookies from "js-cookie";
import CopyDiv from "../../components/CopyDiv/CopyDiv.js";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Orders.css";
import { TypeAnimation } from "react-type-animation";
import SlotNumber from "../../components/SlotNumber/SlotNumber.js";

import marketplaces from "../../config/marketplaces.json";

import { UserContext } from "../../App.js";
import Loader from "../../components/Loader/Loader.js";

const nfeStatuses = {
    AguardandoAutorizacao: "Aguardando Autorização",
    SolicitandoAutorizacao: "Solicitando Autorização",
    AutorizacaoSolicitada: "Autorização Solicitada",
    EmProcessoDeAutorizacao: "Processando Autorização",
    AutorizadaAguardandoGeracaoPDF: "Autorizada! Gerando PDF",
    Autorizada: "Autorizada",
    Negada: "Rejeitada pela SEFAZ",
    SolicitandoCancelamento: "Solicitando Cancelamento",
    CancelamentoSolicitado: "Cancelamento Solicitado",
    EmProcessoDeCancelamento: "Cancelando",
    CanceladaAguardandoAtualizacaoPDF: "Cancelada",
    Cancelada: "Cancelada",
    CancelamentoNegado: "Cancelamento Rejeitado",
};

const statuses = {
    0: {
        id: 0,
        text: "Processando",
        color: "#212121",
        information: "Seu pedido está sendo embalado na velocidade da luz e logo estará disponível para coleta/envio.",
    },
    1: {
        id: 1,
        text: "Coletado",
        color: "rgb(10 119 10)",
        information: "Seu pedido foi coletado com sucesso! Agora, acompanhe o envio pelo marketplace ou pelo site da transportadora.",
    },
    2: {
        id: 2,
        text: "Cancelado",
        color: "#c93838",
        information: "Esse pedido foi cancelado, então não houve cobrança ou seu saldo foi estornado.",
    },
    3: {
        id: 3,
        text: "Pendente",
        color: "#c93838",
        information: "Seu pedido está pendente! Verifique todos os dados na sua plataforma integradora e no marketplace.",
    },
    4: {
        id: 4,
        text: "Saldo insuficiente",
        color: "#c93838",
        information: "Você não tem saldo o suficiente para completar esse pedido! Por favor, insire saldo para que seu pedido não atrase.",
    },
    5: {
        id: 5,
        text: "Aguardando Coleta",
        color: "#1567D2",
        information: "Seu pedido já foi embalado, mas ainda não foi coletado pela transportadora/marketplace!",
    },
    6: {
        id: 6,
        text: "Falta Personalizar",
        color: "#c93838",
        information: "Seu pedido contém itens personalizáveis, porém não estão preenchidos! Abra o pedido e preencha-os corretamente.",
    },
    7: {
        id: 7,
        text: "Cancelamento Solicitado",
        color: "#d48026",
        information: "Você solicitou o cancelamento deste pedido, mas como já estava Embalado ou Coletado, nossa equipe precisa aprovar o cancelamento.",
    },
    99: {
        id: 99,
        text: "Pedido de Teste",
        color: "#5832a8",
        information: "Esse é um pedido de teste na plataforma e não será enviado.",
    },
};

let searchTimeout;

const getPlpImage = (plpUrl) => {
    return new Promise(async (resolve, reject) => {
        if (!plpUrl) reject();
        const plpResponse = await fetch(`/uploads/orders/${plpUrl}`).catch(reject);
        let plpData = await plpResponse.text();
        if (!plpData) reject();
        const response = await fetch("https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/", {
            method: "POST",
            body: plpData,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });
        let blob = await response.blob();

        blobToBase64(blob, function (image) {
            resolve(`data:image/png;base64,${image}`);
        });
    });
};

const onChangePlp = async (e, order) => {
    const formData = new FormData();
    formData.append("plp", e.target.files[0]);
    fetch(`/api/orders/changeplp?id=${order.id}`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + Cookies.get("jwt"),
        },
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => {
            e.target.value = "";
        })
        .catch((error) => {
            alert("Tipo de arquivo inválido");
            e.target.value = "";
            e.target.files = "";
        });
};

const onChangeNfe = async (e, order) => {
    const formData = new FormData();
    formData.append("nfe", e.target.files[0]);
    fetch(`/api/orders/changenfe?id=${order.id}`, {
        method: "POST",
        headers: {
            Authorization: "Bearer " + Cookies.get("jwt"),
        },
        body: formData,
    })
        .then((response) => response.json())
        .then((data) => {
            e.target.value = "";
        })
        .catch((error) => {
            alert("Tipo de arquivo inválido");
            e.target.value = "";
        });
};

export default function Orders() {
    const { orders, setOrders, modals } = useContext(UserContext);

    const [search, setSearch] = useState("");

    const [modalOrder, setModalOrder] = useState(false);
    const [modalOrderObj, setModalOrderObj] = useState({});

    modals.current.setModalOrder = setModalOrder;
    modals.current.setModalOrderObj = setModalOrderObj;

    useEffect(() => {
        modals.current.modalOrderObj = modalOrderObj;
    }, [modalOrderObj]);

    const [modalPlp, setModalPlp] = useState(false);
    const [modalNfe, setModalNfe] = useState(false);

    modals.current.setModalPlp = setModalPlp;
    modals.current.setModalNfe = setModalNfe;

    const [modalCancel, setModalCancel] = useState(false);

    const [plp, setPlp] = useState(false);

    const [boxQtty, setBoxQtty] = useState(0);
    const [giftQtty, setGiftQtty] = useState(0);

    const [productData, setProductData] = useState({});
    const [modalBurn, setModalBurn] = useState(false);

    const [productNames, setProductNames] = useState([]);

    const [hasMore, setHasMore] = useState(true);

    const orderList = useRef(null);

    // const fetchOrders = async (offset) => {
    //     console.log("Fetching orders");
    //     try {
    //         const response = await fetch(`/api/orders/mine?offset=${offset || 0}`);
    //         const data = await response.json();
    //         // console.log(Object.fromEntries(data.map((e) => [e.id, e])));

    //         setOrders(orders => ({...orders, ...Object.fromEntries(data.map((e) => [e.id, e]))}));

    //         return("ok");

    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    // useEffect(() => {
    //     if (!orders.length) fetchOrders();
    // }, []);

    useEffect(() => {
        modalOrderObj.order_products &&
            setGiftQtty(
                Object.keys(modalOrderObj.order_products).reduce((accumulator, key) => {
                    let value = modalOrderObj.order_products[key];
                    return accumulator + (value.options.gift ? (value.options.globalGift == 1 ? 1 : value.qtty) : 0);
                }, 0)
            );

        modalOrderObj.order_products &&
            setBoxQtty(
                Object.keys(modalOrderObj.order_products).reduce((accumulator, key) => {
                    let value = modalOrderObj.order_products[key];
                    return accumulator + (value.options.box ? value.options.boxQtty : 0);
                }, 0)
            );
    }, [modalOrderObj]);

    const [hideSearch, setHideSearch] = useState(false);
    const [ordersSearch, setOrdersSearch] = useState([]);

    const [page, setPage] = useState(1);

    useEffect(() => {
        // if (Object.values(modalOrderObj).length) setModalOrderObj(Object.values(orders).find(o => o.id === modalOrderObj.id));
        clearTimeout(searchTimeout);

        if (!search)
            return setOrdersSearch(
                Object.values(orders)
                    .reverse()
                    .slice(0, page * 20)
            );
        let query = search.toLowerCase();
        let newOrders = Object.values(orders)
            .reverse()
            .filter((order) => {
                let skus = order.order_products.map((e) => e.product?.sku.toLowerCase()).join(" ");
                let date = new Date(order.createdAt)
                    .toLocaleString("pt-BR")
                    .split(", ")
                    .reverse()
                    .join("\n")
                    .replace(/(:\d\d\n)/, "\n");
                return (
                    skus.includes(query) ||
                    date.includes(query) ||
                    order.id.toString().padStart(6, 0).includes(query) ||
                    marketplaces[order.marketplace].name.toLowerCase().includes(query) ||
                    query.includes(marketplaces[order.marketplace].name.toLowerCase()) ||
                    order.data?.contato?.nome?.toLowerCase().includes(query)
                );
            });
        searchTimeout = setTimeout(() => setOrdersSearch(newOrders.slice(0, page * 30)), 1000);
    }, [orders, search, page]);

    const loadMore = (args) => {
        console.log("loading more");
        setTimeout(() => setPage((pg) => pg + 1), 1000);
        return "ok";
    };

    return (
        <>
            <div
                className={`modal-product-bg modal-burn-bg ${modalBurn ? "shown" : ""}`}
                onClick={() => {
                    setModalBurn(false);
                    setTimeout(() => setProductData({}), 200);
                    setTimeout(() => setProductNames([]), 200);
                }}
            >
                <div className="modal-options modal-custom" onClick={(ev) => ev.stopPropagation()}>
                    Insira {productData?.qtty > 1 ? "os nomes" : "o nome"} a gravar:
                    {modalBurn &&
                        Array(productData?.qtty)
                            .fill("")
                            .map((e, i) => {
                                return (
                                    <div className="modal-custom-input" key={i}>
                                        {i + 1}
                                        <input
                                            maxLength={16}
                                            value={productNames[i] || ""}
                                            onChange={(ev) => {
                                                let newSelected = productNames?.slice() || [];

                                                newSelected[i] = ev.target.value.toUpperCase();
                                                setProductNames(newSelected);
                                            }}
                                        />
                                    </div>
                                );
                            })}
                    <div
                        className="burn-save"
                        onClick={async () => {
                            if (productNames?.length < productData?.qtty) alert("Você precisa preencher todos os nomes!");
                            const savenames = await fetch("/api/orders/savenames", {
                                method: "POST",
                                body: JSON.stringify({
                                    orderId: modalOrderObj.id,
                                    productId: productData.id,
                                    names: productNames,
                                }),
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            });

                            let newObj = await savenames.json();

                            console.log(newObj);
                            setModalOrderObj(newObj);

                            setModalBurn(false);
                            setProductNames([]);
                        }}
                    >
                        Salvar
                    </div>
                </div>
            </div>

            <div className={`order-modal-bg ${modalOrder ? "shown" : ""}`} onClick={() => setModalOrder(false)}>
                <div className="order-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="order-modal-header">
                        <div className="order-modal-header-section order-modal-number">
                            <small>Pedido nº</small>
                            {modalOrder ? <SlotNumber delay={500} number={("" + modalOrderObj.id).padStart(6, 0)} height={20} /> : ""}
                        </div>
                        <div className="order-modal-header-section order-modal-date">
                            {new Date(modalOrderObj?.createdAt)
                                .toLocaleString("pt-BR")
                                .split(", ")
                                .reverse()
                                .join("\n")
                                .replace(/(:\d\d\n)/, "\n")}
                        </div>
                        <div className="order-modal-header-section order-marketplace">
                            <img src={marketplaces[modalOrderObj?.marketplace]?.img || marketplaces[marketplaces[modalOrderObj?.marketplace]?.parentId]?.img} />
                        </div>
                        <div className="order-modal-header-section order-buttons">
                            <label
                                className={`order-button ${
                                    !modalOrderObj?.plp || (modalOrderObj?.plp === "error" && modalOrderObj.status === 3) ? "notfound" : ""
                                }`}
                            >
                                <div className="order-button-bubble">
                                    {(!modalOrderObj?.plp || modalOrderObj?.plp === "error") && modalOrderObj?.status === 3 ? "Anexe a PLP" : "PLP"}
                                </div>
                                {!modalOrderObj?.plp && modalOrderObj?.status === 3 ? <input type="file" accept=".txt,.zpl" /> : <></>}
                                <img
                                    src="/img/orders/plp.svg"
                                    onClick={async (ev) => {
                                        ev.stopPropagation();
                                        if (modalOrderObj.plp) {
                                            setModalPlp(true);
                                            setPlp(await getPlpImage(modalOrderObj?.plp));
                                        }
                                    }}
                                />
                            </label>
                            <label className={`order-button ${!modalOrderObj?.nfe && modalOrderObj?.status === 3 ? "notfound" : ""}`}>
                                <div className="order-button-bubble">{!modalOrderObj?.nfe && modalOrderObj?.status === 3 ? "Anexe a NFe" : "NFe"}</div>
                                {!modalOrderObj?.nfe && modalOrderObj?.status === 3 ? <input type="file" accept=".pdf" /> : <></>}
                                <img
                                    src="/img/orders/nfe.svg"
                                    onClick={(ev) => {
                                        ev.stopPropagation();
                                        if (modalOrderObj.nfe) setModalNfe(true);
                                    }}
                                />
                            </label>
                        </div>
                        {[0, 1, 3, 4, 5, 6, 99].includes(modalOrderObj?.status) ? (
                            <div
                                className="order-modal-cancel"
                                onClick={() => {
                                    setModalCancel(true);
                                }}
                            >
                                Cancelar
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="order-modal-body">
                        {modalOrderObj.data && (modalOrderObj?.data?.numeroLoja || modalOrderObj?.meliId || modalOrderObj?.magaluId) ? (
                            <div className="order-modal-details">
                                {modalOrderObj?.meliId ? (
                                    <a
                                        href={`https://www.mercadolivre.com.br/vendas/${modalOrderObj?.data?.numeroLoja || modalOrderObj?.meliId}/detalhe`}
                                        className="order-modal-marketplaceid"
                                        target="_blank"
                                    >
                                        #{modalOrderObj?.data?.numeroLoja || modalOrderObj?.meliId}
                                    </a>
                                ) : modalOrderObj?.magaluId ? (
                                    <a
                                        href={`https://seller.magalu.com/pedidos/${modalOrderObj?.data?.code}/${modalOrderObj?.data?.deliveries[0]?.id}`}
                                        className="order-modal-marketplaceid"
                                        target="_blank"
                                    >
                                        LU-{modalOrderObj?.data?.code}
                                    </a>
                                ) : (
                                    <CopyDiv className="order-modal-marketplaceid" value={modalOrderObj?.data?.numeroLoja || modalOrderObj?.meliId}>
                                        #{modalOrderObj?.data?.numeroLoja || modalOrderObj?.meliId}
                                    </CopyDiv>
                                )}
                                <CopyDiv
                                    className="order-modal-buyername"
                                    value={
                                        modalOrderObj?.data?.contato?.nome ||
                                        modalOrderObj?.data?.order?.buyer?.nickname ||
                                        modalOrderObj?.data?.customer?.name ||
                                        ""
                                    }
                                >
                                    {modalOrderObj?.data?.contato?.nome ||
                                        modalOrderObj?.data?.order?.buyer?.nickname ||
                                        modalOrderObj?.data?.customer?.name?.split(" ").slice(0, 2).join(" ") ||
                                        ""}
                                </CopyDiv>
                            </div>
                        ) : (
                            ""
                        )}

                        {modalOrderObj?.notafiscal ? (
                            <>
                                <div className="order-modal-details">
                                    Status NFe: {nfeStatuses[modalOrderObj?.notafiscal?.status] || "Desconhecido"}
                                    {/* {modalOrderObj?.notafiscal?.status == "AguardandoAutorizacao"
                                        ? "Processando"
                                        : modalOrderObj?.notafiscal?.status == "Autorizada"
                                        ? "Autorizada"
                                        : "Erro ao gerar"} */}
                                    {modalOrderObj?.notafiscal?.json?.linkDownloadXml ? (
                                        <a href={modalOrderObj?.notafiscal?.json?.linkDownloadXml} className="download-xml">
                                            Baixar XML
                                        </a>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="order-status-block">
                            {modalOrder ? (
                                <div className="order-timeline">
                                    <div
                                        className={`order-timeline-circle ${modalOrderObj?.status === 3 || modalOrderObj?.status === 4 ? "pending" : ""} ${
                                            modalOrderObj?.status === 3 || modalOrderObj?.status === 4 ? "advance" : "advanced"
                                        }`}
                                    >
                                        <div className="order-timeline-subtitle">{modalOrderObj?.status === 4 ? "Sem saldo" : "Pendente"}</div>
                                    </div>
                                    <div
                                        className={`order-timeline-line ${
                                            modalOrderObj?.status === 3 || modalOrderObj?.status === 4
                                                ? "pending advance"
                                                : modalOrderObj?.status === 0
                                                ? "advance"
                                                : modalOrderObj?.status === 1 || modalOrderObj?.status === 5
                                                ? "advanced"
                                                : "advanced"
                                        }`}
                                    ></div>
                                    <div
                                        className={`order-timeline-circle ${
                                            modalOrderObj?.status === 0
                                                ? "advance"
                                                : modalOrderObj?.status === 1 || modalOrderObj?.status === 5
                                                ? "advanced"
                                                : modalOrderObj?.status === 2 || modalOrderObj?.status === 7
                                                ? "advanced"
                                                : ""
                                        }`}
                                    >
                                        <div className="order-timeline-subtitle">Processando</div>
                                    </div>
                                    {modalOrderObj?.status === 2 || modalOrderObj?.status === 7 ? (
                                        <>
                                            <div
                                                className={`order-timeline-line canceled ${
                                                    modalOrderObj?.status === 2 || modalOrderObj?.status === 7 ? "advance" : ""
                                                }`}
                                            ></div>
                                            <div
                                                className={`order-timeline-circle canceled ${
                                                    modalOrderObj?.status === 2 || modalOrderObj?.status === 7 ? "advance" : ""
                                                }`}
                                            >
                                                <div className="order-timeline-subtitle canceled">
                                                    {modalOrderObj?.status === 7 ? "Cancel. Solicitado" : "Cancelado"}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                className={`order-timeline-line ${
                                                    modalOrderObj?.status === 1 ? "advance2" : modalOrderObj?.status === 5 ? "advance" : ""
                                                }`}
                                            ></div>
                                            <div
                                                className={`order-timeline-circle ${
                                                    modalOrderObj?.status === 1 ? "advance2" : modalOrderObj?.status === 5 ? "advance" : ""
                                                }`}
                                            >
                                                <div className="order-timeline-subtitle">
                                                    {modalOrderObj?.status === 1
                                                        ? "Coletado"
                                                        : modalOrderObj?.status === 5
                                                        ? "Aguard. Coleta"
                                                        : "Aguard. Coleta"}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : (
                                ""
                            )}
                            <div className="order-status-explanation">
                                {statuses[modalOrderObj?.status || 0].information}
                                {modalOrderObj?.options?.cancelReason ? (
                                    <div className="cancel-reason-container">
                                        Motivo do cancelamento: <div className="cancel-reason">{modalOrderObj?.options.cancelReason}</div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="order-modal-products-shadow">
                            <div className="order-modal-products">
                                {modalOrder
                                    ? modalOrderObj?.order_products.map((e, i) => {
                                          if (modalOrderObj?.order_products[i - 1] && modalOrderObj?.order_products[i - 1].product?.id === e.product?.id)
                                              return;

                                          let giftQtty = modalOrderObj?.order_products
                                              .filter((p) => p.product?.id === e.product?.id)
                                              .reduce((accum, curr) => {
                                                  if (Object.values(curr?.options).find((f) => f.name === "gift")) return accum + 1;
                                                  else return accum;
                                              }, 0);
                                          let boxQtty = modalOrderObj?.order_products
                                              .filter((p) => p.product?.id === e.product?.id)
                                              .reduce((accum, curr) => {
                                                  if (Object.values(curr?.options).find((f) => f.name === "box")) return accum + 1;
                                                  else return accum;
                                              }, 0);

                                          let names = modalOrderObj?.order_products
                                              .filter((p) => p.product?.id === e.product?.id)
                                              .reduce((accum, curr) => {
                                                  let name = Object.values(curr?.options).find((f) => f.name === "name");
                                                  if (name) return [...accum, name.value];
                                              }, []);
                                          return (
                                              <div
                                                  className={`order-modal-product ${e.product.custom ? "product-order-custom" : ""} ${
                                                      e.product.custom && !names?.length && (modalOrderObj.status === 6 || modalOrderObj.status === 0)
                                                          ? "missingnames"
                                                          : ""
                                                  }`}
                                                  style={{
                                                      animationDelay: `${i * 30 + 350}ms`,
                                                  }}
                                                  key={e.product.id}
                                                  onClick={() => {
                                                      if (e.product.custom && (modalOrderObj.status === 6 || modalOrderObj.status === 0)) {
                                                          setProductNames(
                                                              modalOrderObj?.order_products
                                                                  ?.filter((op) => op.product.id === e.product.id)
                                                                  ?.map((op) => op.options?.filter((ef) => ef?.name === "name")?.map((ef) => ef?.value))
                                                          );
                                                          setProductData({
                                                              qtty: e.qtty,
                                                              id: e.product.id,
                                                              order: modalOrderObj?.id,
                                                          });
                                                          setModalBurn(true);
                                                      }
                                                  }}
                                              >
                                                  <div className="order-modal-product-sku">{e.product?.sku}</div>
                                                  {names?.length ? (
                                                      <div className="order-modal-product-names">
                                                          {names.map((name, i) => {
                                                              return <div className="order-modal-product-name">{name?.length ? name : "Sem nome"}</div>;
                                                          })}
                                                      </div>
                                                  ) : (
                                                      <></>
                                                  )}

                                                  <div className="order-modal-product-image">
                                                      <img src={`https://orizom.com.br/uploads/thumbs/${e.product?.photos[0]}`} />
                                                      <div className={`order-product-qtty ${e.qtty > 1 ? "shown" : ""}`}>{e.qtty}</div>
                                                  </div>
                                                  <div className="order-modal-product-price">
                                                      <small>R$</small> {e.price}
                                                  </div>
                                                  <div className="order-modal-product-options">
                                                      {Object.values(e.options).find((f) => f.name === "box") ? (
                                                          <div className="order-product-option">
                                                              {boxQtty} x
                                                              <img src="/img/caixa.svg" />=
                                                              <span>
                                                                  <small>R$ </small>
                                                                  {boxQtty * 5}
                                                              </span>
                                                          </div>
                                                      ) : (
                                                          ""
                                                      )}

                                                      {Object.values(e.options).find((f) => f.name === "gift") ? (
                                                          <div className="order-product-option">
                                                              {giftQtty} x
                                                              <img src="/img/presente.svg" />=
                                                              <span>
                                                                  <small>R$ </small>
                                                                  {giftQtty * 2}
                                                              </span>
                                                          </div>
                                                      ) : (
                                                          ""
                                                      )}
                                                  </div>
                                                  {e.product.custom && (modalOrderObj.status === 6 || modalOrderObj.status === 0) ? (
                                                      <img src={`/img/meli/pencil.svg`} className="order-product-edit" />
                                                  ) : (
                                                      <></>
                                                  )}
                                              </div>
                                          );
                                      })
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="order-modal-total">
                        <div className="order-modal-total-section order-modal-options">
                            {giftQtty ? (
                                <div className="order-modal-option">
                                    {giftQtty}x<img src="/img/presente.svg" />
                                    <div className="order-modal-option-price">
                                        <small>R$ </small>
                                        {giftQtty * 2}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {boxQtty ? (
                                <div className="order-modal-option">
                                    {boxQtty}x<img src="/img/caixa.svg" />
                                    <div className="order-modal-option-price">
                                        <small>R$ </small>
                                        {boxQtty * 5}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="order-modal-total-section">
                            Pago
                            <span className="order-modal-total-value">
                                <small>R$ </small>
                                {modalOrderObj.price}
                            </span>
                        </div>
                        <div className="order-modal-total-section">
                            Vendido
                            <span className="order-modal-total-value">
                                <small>R$ </small>
                                {(modalOrderObj.earned || 0).toFixed(2).replace(".", ",")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`order-modal-bg ${modalCancel ? "shown" : ""}`} onClick={() => setModalCancel(false)}>
                <div className="order-modal order-cancel" onClick={(ev) => ev.stopPropagation()}>
                    {[1, 5].includes(modalOrderObj.status) ? (
                        <>
                            Seu pedido já consta como {statuses[modalOrderObj.status].text},<br /> então será necessário verificação de nossa equipe. <br />
                            Confirma a solicitação de cancelamento?
                        </>
                    ) : (
                        <>
                            Tem certeza que deseja
                            <br /> cancelar o pedido?
                        </>
                    )}
                    <div className="cancel-btns">
                        <div className="cancel-btn-no" onClick={() => setModalCancel(false)}>
                            Não
                        </div>
                        <div
                            className="cancel-btn-yes"
                            onClick={async () => {
                                await fetch("/api/orders/cancel", {
                                    method: "POST",
                                    body: JSON.stringify({
                                        orderId: modalOrderObj?.id,
                                    }),
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + Cookies.get("jwt"),
                                    },
                                });
                                setModalCancel(false);
                                setModalOrder(false);
                            }}
                        >
                            Sim
                        </div>
                    </div>
                </div>
            </div>
            <div className={`order-modal-bg ${modalNfe ? "shown" : ""}`} onClick={() => setModalNfe(false)}>
                <div className="order-modal order-modal-plp order-modal-nfe" onClick={(ev) => ev.stopPropagation()}>
                    <iframe
                        src={
                            `${window.location.protocol}//${
                                window.location.hostname == "localhost" ? `${window.location.hostname}:5000` : window.location.hostname
                            }/uploads/orders/` + modalOrderObj?.nfe
                        }
                        type="application/pdf"
                        width="400px"
                        height="550px"
                    />
                </div>
            </div>

            <div
                className={`order-modal-bg ${modalPlp ? "shown" : ""}`}
                onClick={() => {
                    setModalPlp(false);
                    setPlp(null);
                }}
            >
                <div className="order-modal order-modal-plp" onClick={(ev) => ev.stopPropagation()}>
                    <img className="plp-img" src={plp} />
                </div>
            </div>
            <div className="order-list" ref={orderList}>
                {/*JSON.stringify(orders)*/}
                {ordersSearch && Object.keys(ordersSearch)?.length ? (
                    <InfiniteScroll
                        // scrollableTarget={orderList.current}
                        className="order-list-infinite"
                        dataLength={Object.keys(ordersSearch)?.length} //This is important field to render the next data
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<Loader />}
                        // endMessage={
                        //     <p style={{ textAlign: 'center' }}>
                        //     <b>Yay! You have seen it all</b>
                        //     </p>
                        // }
                        // below props only if you need pull down functionality
                        // refreshFunction={console.log}
                        // pullDownToRefresh
                        // pullDownToRefreshThreshold={50}
                        // pullDownToRefreshContent={
                        //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                        // }
                        // releaseToRefreshContent={
                        //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                        // }
                    >
                        {ordersSearch.map((order, index) => {
                            return (
                                <Order
                                    setModalNfe={setModalNfe}
                                    setPlp={setPlp}
                                    setModalPlp={setModalPlp}
                                    setModalOrderObj={setModalOrderObj}
                                    order={order}
                                    index={index}
                                    key={order.id}
                                    onClick={() => {
                                        setModalOrderObj(order);
                                        setModalOrder(true);
                                    }}
                                />
                            );
                        })}
                    </InfiniteScroll>
                ) : (
                    <></>
                )}
            </div>
            <div className="order-search-bar">
                <div className="order-search">
                    <input
                        // className="order-search"
                        placeholder={window.innerWidth < 768 ? "Pedido, sku, data" : "Pesquise por nº do pedido, sku, data, etc..."}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                            // setSearchParams({'q': e.target.value});
                        }}
                        onFocus={() => setHideSearch(true)}
                    />
                    <div className="order-search-qtty">
                        {(search ? ordersSearch : Object.keys(orders)).length
                            ? `${(search ? ordersSearch : Object.keys(orders)).length} Pedido${
                                  (search ? ordersSearch : Object.keys(orders)).length > 1 ? "s" : ""
                              }`
                            : ""}
                    </div>
                </div>
                <div className={`order-search-placeholder ${hideSearch ? "hidden" : ""}`}>
                    <TypeAnimation
                        sequence={[1500, "Pesquise por nº do pedido, sku, data, etc..."]}
                        // wrapper="span"
                        speed={{ type: "keyStrokeDelayInMs", value: 25 }}
                    />
                </div>
            </div>
        </>
    );
}

function Order(props) {
    const status = statuses[props.order.status];

    return (
        <div className="order-card" onClick={props.onClick}>
            <div className="order-section order-photo">
                {props.order.order_products.length > 1 ? (
                    <div className="multiple-order-photos">
                        <img src={`https://orizom.com.br/uploads/thumbs/${props.order.order_products[0].product?.photos[0]}`} />
                        <img src={`https://orizom.com.br/uploads/thumbs/${props.order.order_products[1].product?.photos[0]}`} />
                        <img
                            src={`https://orizom.com.br/uploads/thumbs/${
                                props.order.order_products[2]
                                    ? props.order.order_products[2].product?.photos[0]
                                    : props.order.order_products[1].product?.photos[0]
                            }`}
                        />
                    </div>
                ) : (
                    <img
                        src={`https://orizom.com.br/uploads/thumbs/${
                            props.order.order_products[0] ? props.order.order_products[0].product?.photos[0] : "ff.jpg"
                        }`}
                    />
                )}
                <div className="order-photo-sku">
                    {props.order.order_products.length > 1 ? "Diversos" : props.order.order_products[0] ? props.order.order_products[0].product?.sku : "ERRO"}
                </div>
            </div>
            <CopyDiv className="order-section order-number" value={("" + props.order.id).padStart(6, 0)}>
                {("" + props.order.id).padStart(6, 0)}
            </CopyDiv>
            <div className="order-section order-price">
                <div className="order-price-internal">
                    Pago
                    <div className="order-price-value">
                        <small>R$</small> {props.order.price}
                    </div>
                </div>
                <div className="order-price-internal">
                    Vendido
                    <div className="order-price-value">
                        <small>R$</small> {(props.order.earned || props.order.price).toFixed(2).replace(".", ",")}
                    </div>
                </div>
            </div>
            <div className="order-section order-marketplace">
                <img src={marketplaces[props.order.marketplace]?.img || marketplaces[marketplaces[props.order.marketplace]?.parentId]?.img} />
                {props.order.idBling ? <img className="order-bling-icon" src="/img/orders/bling.svg" /> : <></>}
                {props.order.meliId || props.order.magaluId ? <div className="order-bling-icon">AI-stem</div> : <></>}
            </div>
            <div className="order-section order-buttons">
                {/* <div
                    className={`order-message order-button ${
                        Math.random() > 2 ? "new-messages" : ""
                    }`}
                >
                    <div className="order-button-bubble">Mensagens</div>
                    <img src="/img/orders/message.svg" className="no-message" />
                    <div className="with-message">
                        <img src="/img/orders/message-filled.svg" />
                        <span className="order-message-qtty">
                            {Math.round(Math.random() * 9 + 1)}
                        </span>
                    </div>
                </div> */}
                <label
                    className={`order-button ${(!props.order.plp || props.order.plp === "error") && props.order.status === 3 ? "notfound" : ""}`}
                    onClick={async (ev) => {
                        ev.stopPropagation();
                        if (props.order.plp) {
                            props.setModalPlp(true);
                            props.setPlp(await getPlpImage(props.order.plp));
                        }
                    }}
                >
                    {!props.order.plp && props.order.status === 3 ? (
                        <input type="file" accept=".txt,.zpl" onChange={(e) => onChangePlp(e, props.order)} />
                    ) : (
                        <></>
                    )}
                    <div className="order-button-bubble">
                        {(!props.order.plp || props.order.plp === "error") && props.order.status === 3 ? "Anexe a PLP" : "PLP"}
                    </div>
                    <img src="/img/orders/plp.svg" />
                </label>
                <label
                    className={`order-button ${!props.order.nfe && props.order.status === 3 ? "notfound" : ""}`}
                    onClick={(ev) => {
                        ev.stopPropagation();
                        if (props.order.nfe) {
                            props.setModalOrderObj(props.order);
                            props.setModalNfe(true);
                        }
                    }}
                >
                    <div className="order-button-bubble">{!props.order.nfe && props.order.status === 3 ? "Anexe a NFe" : "NFe"}</div>
                    {!props.order.nfe && props.order.status === 3 ? <input type="file" accept=".pdf" onChange={(e) => onChangeNfe(e, props.order)} /> : <></>}
                    <img src="/img/orders/nfe.svg" />
                </label>
            </div>
            <div className="order-section order-date">
                {new Date(props.order.createdAt)
                    .toLocaleString("pt-BR")
                    .split(",")
                    .reverse()
                    .join("\n")
                    .replace(/(:\d\d\n)/, "\n")}
            </div>
            <div className={`order-section order-status order-status-pc`} style={{ backgroundColor: status.color }} onClick={(ev) => ev.stopPropagation()}>
                {status.text}
                <div className="order-status-bubble">{status.information}</div>
            </div>
            <div className={`order-section order-status order-status-mobile`} style={{ backgroundColor: status.color }} onClick={(ev) => ev.stopPropagation()}>
                {status.text?.slice(0, 1)}
                <div className="order-status-bubble">{status.information}</div>
            </div>
        </div>
    );
}

var blobToBase64 = function (blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(",")[1];
        callback(base64);
    };
    reader.readAsDataURL(blob);
};
