import { use, useEffect } from "react";
import { UserContext } from "../../App";
import { getEtq, onlinePrint } from "../../util/utils";
import "./MiniBox.css";
import { useContext, useRef, useState } from "react";
function MiniBox({ slot, product, label, setShowPopup, setPopupData, setProductPopup, search, value, slotQtty }) {
    const { etqPrinter, zebraPrint, config } = useContext(UserContext);

    const [fontSize, setFontSize] = useState(20);

    const columns = 10;

    const labelText = useRef();

    useEffect(() => {
        if (labelText.current.offsetWidth > 90) {
            setFontSize((f) => f - 2);
        }
    }, [fontSize]);

    return (
        <div className={`admin-box ${search.includes(product?.id) ? "open" : ""} ${slot?.qtty < 1 && value > 1 ? "urgent" : ""}`}>
            {product ? (
                <div className={`mini-box-qtty ${slot?.qtty < 1 ? "empty" : ""} ${value ? "" : "toomany"}`}>
                    {slot?.qtty || 0}/{value ? Math.ceil((value * 0.5) / (slotQtty || 1)) : 0}
                </div>
            ) : (
                <></>
            )}
            {product ? (
                <div className="box">
                    <img
                        className="admin-box-product"
                        src={`https://orizom.com.br/uploads/thumbs/${product?.photos[0]}`}
                        onClick={() => {
                            setPopupData(slot);
                            setProductPopup(true);
                        }}
                    />
                    <div className="side front">
                        <div className="side-label" ref={labelText} style={{ fontSize: `${fontSize}px` }}>
                            {product?.sku}
                            <img src="/img/starbox/barcode.png" />
                        </div>
                    </div>
                    <div className="side left" />
                    <div className="side back"></div>
                    <div className="side right"></div>
                    <div className="side bottom"></div>
                    <div className="flap front"></div>
                    <div className="flap back"></div>
                    <div className="flap left">
                        <div className="box-slot-tag">{label}</div>
                    </div>
                    <div className="flap right"></div>
                </div>
            ) : (
                <div
                    className="box-add-product"
                    onClick={() => {
                        setPopupData(slot);
                        setProductPopup(true);
                    }}
                >
                    +
                </div>
            )}
            <div className="slot-label">{label}</div>
            <div className={`slot-buttons ${product ? "hasproduct" : ""}`}>
                {product ? (
                    <>
                        <div className="slot-btn qtty-slot">
                            <span
                                className="qtty-slot-btn"
                                onClick={() => {
                                    if (slot.qtty < 1) return;
                                    fetch("/api/starbox/updateslot", {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            ...slot,
                                            qtty: slot.qtty - 1,
                                        }),
                                    });
                                    setProductPopup(false);
                                }}
                            >
                                -
                            </span>
                            <span className="qtty-slot-numer">
                                {slot?.qtty || 0}/{value ? Math.ceil((value * 0.5) / (slotQtty || 1)) : 0}
                            </span>
                            <span
                                className="qtty-slot-btn plus"
                                onClick={() => {
                                    fetch("/api/starbox/updateslot", {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                        body: JSON.stringify({
                                            ...slot,
                                            qtty: slot.qtty + 1,
                                        }),
                                    });
                                    setProductPopup(false);
                                }}
                            >
                                +
                            </span>
                        </div>
                        <div
                            className="slot-btn"
                            onClick={() => {
                                fetch("/api/starbox/createtask", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        body: { x: slot?.x, y: slot?.y },
                                        text: `Buscar caixa em ${slot?.label}`,
                                        type: 0,
                                    }),
                                });
                            }}
                        >
                            Buscar caixa
                        </div>
                    </>
                ) : (
                    <></>
                )}
                {config?.starboxManual === true ? (
                    <div
                        className="slot-btn"
                        onClick={() => {
                            setPopupData(slot);
                            setShowPopup(true);
                        }}
                    >
                        Configurar
                    </div>
                ) : (
                    <></>
                )}
                {product ? (
                    <div
                        className="slot-btn"
                        onClick={() => {
                            onlinePrint(1, getEtq(product?.id, product?.sku, product?.name, true));
                            // zebraPrint(etqPrinter, getEtq(product?.id, product?.sku, product?.name, true));
                        }}
                    >
                        Imprimir
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default MiniBox;
