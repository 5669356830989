import React, { useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./Integrations.css";

import Loader from "../../components/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";

import { UserContext } from "../../App.js";
import Einstein from "../../components/Einstein/Einstein.js";
import { TypeAnimation } from "react-type-animation";
import Popup from "../../util/popup.jsx";

export default function Dashboard(props) {
    const navigate = useNavigate();

    const { user, setUser, integrations } = useContext(UserContext);

    const integrationTypes = {
        0: { name: "Bling", url: "bling" },
        1: { name: "Mercado Livre", url: "meli" },
        2: { name: "Magalu", url: "magalu" },
    };

    const [modalAdd, setModalAdd] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalWaiting, setModalWaiting] = useState(false);
    const [modalPending, setModalPending] = useState(false);

    const [modalConfirmMeli, setModalConfirmMeli] = useState(false);

    const [pendingLoading, setPendingLoading] = useState(false);

    const [blingConfig, setBlingConfig] = useState(false);
    const [addMarketplace, setAddMarketplace] = useState(false);
    const [meliConfig, setMeliConfig] = useState(false);

    const [integration, setIntegration] = useState({});

    const [marketplaceData, setMarketplaceData] = useState({ marketplace: 1 });

    const [modalIntegration, setModalIntegration] = useState({});

    const [integrationsArray, setIntegrationsArray] = useState([]);

    const [addMarketplaceLoading, setAddMarketplaceLoading] = useState(false);

    useEffect(() => {
        console.log(Object.values(integrations));
        setIntegrationsArray(Object.values(integrations));
    }, [integrations]);

    useEffect(() => {
        if (window.location.search.includes("bling=success")) {
            setTimeout(() => {
                // setBlingConfig(true);
                setIntegration(Object.values(integrations).find((e) => e.type === 0));
            }, 200);

            setTimeout(() => {
                window.history.pushState({}, "", "/integracoes");
            }, 500);
        }

        if (window.location.search.includes("meli=success") && Object.values(integrations).find((e) => e.type === 1)?.data?.pendences?.length) {
            setTimeout(() => {
                setModalIntegration(Object.values(integrations).find((e) => e.type === 1));
                setModalPending(true);
            }, 200);
        }
    }, []);

    const marketplaces = [
        { id: 1, name: "Mercado Livre", icon: "ml.svg", maxLength: 60 },
        { id: 2, name: "Magalu", icon: "magalu3.svg", maxLength: 120 },
        { id: 3, name: "Amazon", icon: "amazon2.svg", maxLength: 60 },
        { id: 4, name: "Olist", icon: "olist2.svg", maxLength: 60 },
        { id: 5, name: "B2W", icon: "b2w.svg", maxLength: 120 },
        { id: 6, name: "Shopee", icon: "shopee.svg", maxLength: 120 },
        { id: 7, name: "Netshoes", icon: "netshoes.svg", maxLength: 100 },
        { id: 0, name: "Outros", icon: "outros5.svg", maxLength: 120 },
    ];

    return (
        <>
            <div className={`integrations-modal-bg ${blingConfig ? "shown" : ""}`} onClick={() => setBlingConfig(false)}>
                <div className="integrations-bling-modal" onClick={(ev) => ev.stopPropagation()}>
                    Configurações Bling
                    <div className="integration-marketplace-explanation" key={0}>
                        {blingConfig ? (
                            <>
                                <Einstein className="instegrations-einstein" type="say" />
                                <TypeAnimation
                                    className="integration-saying"
                                    sequence={[
                                        1500,
                                        "Ainda não temos nenhuma configuração quanto a essa integração! \nMas, eu estou em constante atualização, então assim que tivermos mais opções, eu te avisarei!",
                                    ]}
                                    speed={{
                                        type: "keyStrokeDelayInMs",
                                        value: 5,
                                    }}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            {/* <div
                className={`integrations-modal-bg ${
                    blingConfig ? "shown" : ""
                }`}
                onClick={() => setBlingConfig(false)}
            >
                <div
                    className="integrations-bling-modal"
                    onClick={(ev) => ev.stopPropagation()}
                >
                    {blingConfig ? (
                        integration?.config2?.length ? (
                            <div
                                className="integration-marketplace-explanation"
                                key={0}
                            >
                                <Einstein
                                    className="instegrations-einstein"
                                    type="say"
                                />
                                <TypeAnimation
                                    className="integration-saying"
                                    sequence={[
                                        1500,
                                        "Caso venda em outros marketplaces, não esqueça de adicioná-los, para que eu todos seus pedidos e produto sejam integrados automaticamente!",
                                    ]}
                                    speed={{
                                        type: "keyStrokeDelayInMs",
                                        value: 10,
                                    }}
                                />
                            </div>
                        ) : (
                            <div
                                className="integration-marketplace-explanation"
                                key={1}
                            >
                                <Einstein
                                    className="instegrations-einstein"
                                    type="say"
                                />
                                <TypeAnimation
                                    className="integration-saying"
                                    sequence={[
                                        1500,
                                        `${
                                            window.location.search.includes(
                                                "bling=success"
                                            )
                                                ? "Opa! Deu tudo certo com sua integração! Agora, estou criando nossas categorias e campos customizados em seu Bling! Não os exclua em hipótese alguma. "
                                                : ""
                                        }
É crucial que faça a conexão dos marketplaces corretamente, para que seus pedidos e produtos sejam integrados corretamente!`,
                                    ]}
                                    speed={{
                                        type: "keyStrokeDelayInMs",
                                        value: 10,
                                    }}
                                />
                            </div>
                        )
                    ) : (
                        <></>
                    )}
                    <div
                        className="btn-add-marketplace"
                        onClick={() => {
                            setAddMarketplace(true);
                        }}
                    >
                        Adicionar marketplace
                    </div>
                    <div className="integration-marketplaces">
                        {integration?.config2?.map((e, i) => {
                            return (
                                <div className="integration-marketplace">
                                    <img
                                        src={
                                            "/places/" +
                                            marketplaces.find(
                                                (m) => m.id === e.marketplace
                                            )?.icon
                                        }
                                    />
                                    <div className="integration-place-name">
                                        {e.name}
                                    </div>
                                    <div className="integration-place-code">
                                        Código Bling
                                        <span>{e.code}</span>
                                    </div>
                                    <div
                                        className="integration-place-remove"
                                        onClick={async () => {
                                            let newConfig =
                                                [
                                                    ...(integration?.config2 ||
                                                        []),
                                                ] || [];
                                            newConfig.splice(i, 1);
                                            setIntegration({
                                                ...integration,
                                                config2: newConfig,
                                            });
                                            await fetch(
                                                "/api/bling/removemarketplace",
                                                {
                                                    method: "DELETE",
                                                    body: JSON.stringify({
                                                        code: e.code,
                                                    }),
                                                    headers: {
                                                        "Content-Type":
                                                            "application/json",
                                                    },
                                                }
                                            );
                                        }}
                                    >
                                        Remover
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div> */}

            <div className={`integrations-modal-bg ${meliConfig ? "shown" : ""}`} onClick={() => setMeliConfig(false)}>
                <div className="integrations-bling-modal" onClick={(ev) => ev.stopPropagation()}>
                    Configurações Mercado Livre
                    <div className="integration-marketplace-explanation" key={0}>
                        {meliConfig ? (
                            <>
                                <Einstein className="instegrations-einstein" type="say" />
                                <TypeAnimation
                                    className="integration-saying"
                                    sequence={[
                                        1500,
                                        "Ainda não temos nenhuma configuração quanto a essa integração! \nMas, eu estou em constante atualização, então assim que tivermos mais opções, eu te avisarei!",
                                    ]}
                                    speed={{
                                        type: "keyStrokeDelayInMs",
                                        value: 5,
                                    }}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>

            <div className={`integrations-modal-bg ${modalPending ? "shown" : ""}`} onClick={() => setModalPending(false)}>
                <div className="integrations-bling-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className={`integrations-add-marketplace-loading ${pendingLoading ? "shown" : ""}`}>
                        <Loader />
                    </div>

                    {modalIntegration?.data?.pendences?.length ? (
                        <>
                            Pendências encontradas
                            {modalIntegration?.data?.pendences?.map((p) => {
                                switch (p) {
                                    case "mercadoenvios":
                                        return (
                                            <div className="integration-pendence">
                                                Sua conta não está habilitada para o <strong>Mercado Envios.</strong>{" "}
                                                <a href="https://www.mercadolivre.com.br/addresses/v2/hub?app=SYI" target="_blank">
                                                    Clique aqui
                                                </a>{" "}
                                                e insira o endereço da Orizom para poder habilitar.
                                            </div>
                                        );
                                    case "wrong_sell_address":
                                        return (
                                            <div className="integration-pendence">
                                                Em sua conta, o endereço de venda é diferente do endereço da Orizom. Para que suas vendas sejam enviadas
                                                corretamente, é essencial que o endereço esteja correto.
                                            </div>
                                        );
                                    case "no_sell_address":
                                        return (
                                            <div className="integration-pendence">
                                                Você não tem nenhum endereço de venda cadastrado em sua conta do Mercado Livre! Você deve cadastrar o endereço
                                                da Orizom, para que suas vendas sejam enviadas corretamente! Você pode encontrar essas informações no "Plano de
                                                voo".
                                            </div>
                                        );
                                    case "phone_pending":
                                        return (
                                            <div className="integration-pendence">
                                                Sua conta do Mercado Livre não está habilitada para criar anúncios, pois você não confirmou seu celular no
                                                Mercado Livre! Você precisa entrar em sua conta lá no marketplace e verificar um número de celular válido.
                                            </div>
                                        );
                                    default:
                                        return (
                                            <div className="integration-pendence">
                                                Há uma pendência em sua conta do Mercado Livre! Código do erro: <strong>{p}</strong>. Caso não saiba o que
                                                significa, entre em contato com o suporte Orizom no Whatsapp!
                                            </div>
                                        );
                                }
                            })}
                            <div
                                className="pendences-update"
                                onClick={async () => {
                                    if (pendingLoading) return;
                                    setPendingLoading(true);

                                    const res = await fetch("/api/mercadolivre/updateaccount", {
                                        method: "GET",
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    });

                                    const newIntegration = await res.json();

                                    if (newIntegration) setModalIntegration(newIntegration);

                                    setPendingLoading(false);
                                }}
                            >
                                Já corrigi, atualizar
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="integration-pendence resolved">
                                Todas as pendências foram corrigidas! Sua integração agora está pronta para ser usada, e a sincronização da Orizom com o Mercado
                                Livre já começou!.
                            </div>
                            <div className="pendences-update" onClick={() => setModalPending(false)}>
                                Pronto
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* <div
                className={`integrations-modal-bg ${
                    addMarketplace ? "shown" : ""
                }`}
                onClick={() => {
                    if (addMarketplaceLoading) return;
                    setAddMarketplace(false);
                    setMarketplaceData({ marketplace: 1 });
                }}
            >
                <div className="integrations-add-marketplace" onClick={(ev) => ev.stopPropagation()}>
                    <div className={`integrations-add-marketplace-loading ${addMarketplaceLoading ? "shown" : ""}`}>
                        <Loader />
                    </div>
                    <div className="integrations-marketplace-list">
                        {marketplaces.map((e) => {
                            return (
                                <div
                                    className={`integration-marketplace-btn ${
                                        marketplaceData.marketplace === e.id ? "selected" : ""
                                    }`}
                                    onClick={() =>
                                        setMarketplaceData({
                                            ...marketplaceData,
                                            marketplace: e.id,
                                        })
                                    }
                                >
                                    <img src={"/places/" + e.icon} />
                                </div>
                            );
                        })}
                    </div>
                    <div className="integrations-marketplace-data">
                        <label>
                            Nome do canal de venda:
                            <input
                                value={marketplaceData.name || ""}
                                onChange={(ev) => {
                                    setMarketplaceData({
                                        ...marketplaceData,
                                        name: ev.target.value.slice(0, 15),
                                    });
                                }}
                            />
                        </label>
                        <label>
                            Código da loja API Bling
                            <input
                                value={marketplaceData.code || ""}
                                onChange={(ev) => {
                                    setMarketplaceData({
                                        ...marketplaceData,
                                        code: ev.target.value.replace(/\D/g, "").slice(0, 12),
                                    });
                                }}
                            />
                            <a href="https://www.youtube.com/watch?v=ItlFsR6D_C4" target="_blank" className="codigo-bling">
                                Como consigo esse código?
                            </a>
                        </label>
                    </div>
                    <div
                        className="integration-marketplace-save"
                        onClick={async () => {
                            try {
                                if (!marketplaceData.name || !marketplaceData.code) return alert("Preencha tudo corretamente!");

                                setAddMarketplaceLoading(true);

                                const result = await fetch("/api/bling/addmarketplace", {
                                    method: "POST",
                                    body: JSON.stringify({
                                        marketplace: marketplaceData,
                                    }),
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                });

                                const data = await result.json();

                                if (data.error) {
                                    switch (data.error) {
                                        case "PLACE_NOT_FOUND":
                                            alert("Não foi encontrada loja do seu Bling com esse código!");
                                            break;
                                        case "ALREADY_EXISTS":
                                            alert("Já existe marketplace com esse código!");
                                            break;
                                        case "ERROR_SYNCING":
                                            alert(
                                                `Há categorias conflitantes cadastradas em sua conta bling: ${data.fields.join(
                                                    ", "
                                                )}. Exclua elas e tente novamente.`
                                            );
                                            break;
                                    }
                                } else {
                                    setIntegration({
                                        ...integration,
                                        config2: [...(integration.config2 || []), marketplaceData],
                                    });
                                    setAddMarketplace(false);
                                    setMarketplaceData({ marketplace: 1 });
                                }
                                setAddMarketplaceLoading(false);
                            } catch (err) {
                                console.error(err);
                            }
                        }}
                    >
                        Salvar
                    </div>
                </div>
            </div> */}

            <div className={`integrations-modal-bg ${modalAdd ? "shown" : ""}`} onClick={() => setModalAdd(false)}>
                <div className="integrations-add-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="integration-add-modal-title">Adicionar integração</div>
                    <div
                        className={`integration-add-btn ${integrationsArray.some((e) => e.type == 0) ? "disabled" : ""}`}
                        onClick={(ev) => {
                            ev.preventDefault();
                            setModalAdd(false);
                            // setModalWaiting(true);
                            window.location.assign(
                                "https://www.bling.com.br/Api/v3/oauth/authorize?response_type=code&client_id=164ee460d532d449d06c23ae3b5cfe84f0c03d75&state=97f44ed0919fc857411a028c5c6b1f74"
                            );

                            // Cookies.set("integration", "false");
                            // let interval;
                            // interval = setInterval(async () => {
                            //     if (Cookies.get("integration") === "true") {
                            //         clearInterval(interval);
                            //         setTimeout(async () => {
                            //             setModalWaiting(false);
                            //             setBlingConfig(true);
                            //         }, 1000);
                            //     }
                            // }, 1000);
                        }}
                    >
                        Bling
                    </div>
                    <div
                        className={`integration-add-btn ${integrationsArray.some((e) => e.type == 1) ? "disabled" : ""}`}
                        onClick={(ev) => {
                            // if (![117, 8].includes(user.id)) return;
                            ev.preventDefault();
                            setModalConfirmMeli(true);
                            // setModalAdd(false);
                            // window.location.assign(
                            //     "https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=8410556149686947&redirect_uri=https://orizom.cloud/api/mercadolivre/auth"
                            // );
                            // setTimeout(
                            //     () =>
                            //         window.open(
                            //             "https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=22322760717096&redirect_uri=https://orizom.cloud/api/mercadolivre/auth",
                            //             "_blank",
                            //             "noopener,noreferrer"
                            //         ),
                            //     100
                            // );
                        }}
                    >
                        Mercado Livre
                        {/* {![117, 8].includes(user.id) ? <div className="em-breve">Em breve!</div> : <></>} */}
                    </div>
                    <div
                        className={`integration-add-btn ${integrationsArray.some((e) => e.type == 2) ? "disabled" : ""}`}
                        onClick={() => {
                            if (!user?.cert)
                                return Popup.fire(
                                    "Atenção!",
                                    `Essa integração necessita da utilização do nosso faturador para emissão de NFe. Vá até a seção "Minha conta" e faça a conexão de seu certificado A1.`
                                );

                            window.location.assign(
                                "https://id.magalu.com/login?client_id=go4CadVJyvIksclf2N-OL6GhaNLKyK4p4vuAoDkgbV8&redirect_uri=https://orizom.com.br/api/magalu/redirect&scope=services:questions-seller:read services:questions-seller:write open:tickets-seller:write open:ticket-messages-seller:write services:conversations-seller:read services:conversations-seller:write open:tickets-seller:read open:ticket-messages-seller:read open:order-order-seller:read open:order-delivery-seller:read open:order-delivery-seller:write open:order-logistics-seller:read open:order-logistics-seller:write open:order-invoice-seller:read open:portfolio-prices-seller:read open:portfolio-prices-seller:write open:portfolio-skus-seller:read open:portfolio-skus-seller:write open:portfolio-stocks-seller:read open:portfolio-stocks-seller:write&response_type=code&choose_tenants=true"
                            );
                        }}
                    >
                        Magalu
                    </div>
                </div>
            </div>
            <div className={`integrations-modal-bg ${modalWaiting ? "shown" : ""}`}>
                <div className="integrations-waiting-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="integration-add-modal-title">Aguardando conexão...</div>
                    <Loader />
                    <div className="integration-modal-confirm-btn" onClick={() => setModalWaiting(false)}>
                        Cancelar
                    </div>
                </div>
            </div>
            <div className={`integrations-modal-bg ${modalConfirm ? "shown" : ""}`} onClick={() => setModalConfirm(false)}>
                <div className="integrations-confirm-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="integration-add-modal-title">Sua integração será removida.</div>
                    <div className="integration-modal-confirm-btns">
                        <div className="integration-modal-confirm-btn" onClick={() => setModalConfirm(false)}>
                            Cancelar
                        </div>
                        <div
                            className="integration-modal-confirm-btn btn-yes"
                            onClick={async () => {
                                await fetch("/api/integrations/remove", {
                                    method: "DELETE",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        id: modalIntegration.id,
                                    }),
                                });
                                setModalConfirm(false);
                            }}
                        >
                            Ok
                        </div>
                    </div>
                </div>
            </div>
            <div className={`integrations-modal-bg ${modalConfirmMeli ? "shown" : ""}`} onClick={() => setModalConfirmMeli(false)}>
                <div className="integrations-confirm-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="meli-warning-title">LEIA ANTES DE PROSSEGUIR</div>
                    <div className="meli-warning-text">
                        Como a Orizom não trabalha com variações, caso você já venda nossos produtos e utilize suas próprias variações, todas elas{" "}
                        <strong>serão apagadas.</strong> Caso não queira perder suas variações, <strong>NÃO</strong> faça a integração e nos avise. Caso
                        contrário, você pode prosseguir normalmente.
                    </div>
                    <div className="integration-modal-confirm-btns">
                        <div className="integration-modal-confirm-btn" onClick={() => setModalConfirmMeli(false)}>
                            Cancelar
                        </div>
                        <div
                            className="integration-modal-confirm-btn btn-yes-meli"
                            onClick={async () => {
                                setModalAdd(false);
                                setModalConfirmMeli(false);
                                window.location.assign(
                                    "https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=8410556149686947&redirect_uri=https://orizom.com.br/api/mercadolivre/auth"
                                );
                            }}
                        >
                            Continuar
                        </div>
                    </div>
                </div>
            </div>
            {integrationsArray ? (
                <div className="integration-container">
                    <div className="add-integration" onClick={() => setModalAdd(true)}>
                        Adicionar integração
                    </div>
                    <div className="integration-container-inside">
                        {integrationsArray.map((integration) => {
                            return (
                                <div className="integration">
                                    <div
                                        className={`integration-syncing ${integration?.data?.pendences && integration?.data?.pendences?.length ? "shown" : ""}`}
                                    >
                                        Integração com pendências.
                                        <div
                                            className="integration-pending"
                                            onClick={() => {
                                                setModalIntegration(integration);
                                                setModalPending(true);
                                            }}
                                        >
                                            Ver pendências
                                        </div>
                                        <div
                                            className="integration-pending-remove"
                                            onClick={() => {
                                                setModalIntegration(integration);
                                                setModalConfirm(true);
                                            }}
                                        >
                                            Remover
                                        </div>
                                    </div>
                                    <div className={`integration-syncing ${integration.syncing ? "shown" : ""}`}>
                                        <Loader />
                                        <div className="syncing-title">Sincronizando</div>
                                        <div className="syncing-bar">
                                            <div
                                                className="syncing-bar-inner"
                                                style={{
                                                    width: `${((integration?.syncingData?.qtty || 1) / (integration?.syncingData?.total || 100)) * 100}%`,
                                                }}
                                            />
                                        </div>
                                        <div className="syncing-qtty">
                                            {integration?.syncingData?.qtty || 0}/{integration?.syncingData?.total || 0} produtos
                                        </div>
                                        {/* <div
                                            className="integration-configuration"
                                            onClick={() => {
                                                if (integration.type === 0)
                                                    setBlingConfig(true);
                                                if (integration.type === 1)
                                                    setMeliConfig(true);
                                                setIntegration(integration);
                                            }}
                                        >
                                            Configurar
                                        </div> */}
                                    </div>
                                    <div className="integration-btn">{integrationTypes[integration.type].name}</div>
                                    <div className="integration-detail">
                                        <span>{integration?.data?.login || integration?.data?.user?.nickname || "Usuário"}</span>
                                    </div>
                                    {/* <div
                                        className="integration-configuration"
                                        onClick={() => {
                                            if (integration.type === 0)
                                                setBlingConfig(true);
                                            if (integration.type === 1)
                                                setMeliConfig(true);
                                            setIntegration(integration);
                                        }}
                                    >
                                        Configurar
                                    </div> */}
                                    <div
                                        className="integration-remove"
                                        onClick={() => {
                                            setModalIntegration(integration);
                                            setModalConfirm(true);
                                        }}
                                    >
                                        Remover
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
}
