import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavbarAdmin from "../components/Admin/NavbarAdmin/NavbarAdmin";
import "./AdminLayout.css";
import AdminAistem from "../components/Admin/AIstem/AIstem.js";
import { onlinePrint } from "../util/utils.js";

export default function AdminLayout(props) {
    const testLabel = `^XA ^CF0,60 ^FO90,100^FDOrizom Etiqueta de Teste^FS ^FO50,250^GB700,3,3^FS ^CFA,30 ^FO50,300^FDCarlos Alberto^FS ^FO50,340^FDRua Manoel Vilaboim, 79^FS ^FO50,380^FDFrancisco Morato^FS ^FO50,420^FDSP - Brasil^FS ^CFA,15 ^FO600,300^GB150,150,3^FS ^FO638,340^FDTeste^FS ^FO638,390^FDOrizom^FS ^FO50,500^GB700,3,3^FS ^BY5,2,270 ^FO70,550^BC^FDORIZOM123^FS ^FO50,900^GB700,250,3^FS ^FO400,900^GB3,250,3^FS ^CF0,40 ^FO100,960^FDOrizom teste^FS ^FO100,1010^FDOrizom teste^FS ^FO100,1060^FDOrizom teste^FS ^CF0,190 ^FO420,955^FDORZ^FS ^XZ`;
    const etqTestLabel = `^XA ^CF0,30 ^FO10,20^FDOrizom Etiqueta de Teste^FS  ^BY2,2,80 ^FO25,80^BC^FDORIZOM123^FS^XZ`;

    const [configModal, setConfigModal] = useState(false);

    const [ZPL1, setZPL1] = useState("");
    const [ZPL2, setZPL2] = useState("");

    useEffect(() => {
        const scripts = [];

        scripts[0] = document.createElement("script");
        scripts[0].src = "/js/pako.js";

        scripts[1] = document.createElement("script");
        scripts[1].src = "/js/zpl-image.js";

        scripts.forEach((s) => {
            document.body.appendChild(s);
        });

        return () => {
            scripts.forEach((s) => {
                document.body.removeChild(s);
            });
        };
    }, []);

    return (
        <>
            <div className={`admin-config-modal-bg ${configModal ? "shown" : ""}`} onClick={() => setConfigModal(false)}>
                <div className="admin-config-modal" onClick={(ev) => ev.stopPropagation()}>
                    <div className="admin-config-printers">
                        <div
                            className="admin-printer-test"
                            onClick={() => {
                                onlinePrint(2, testLabel);
                            }}
                        >
                            Testar
                        </div>
                        <div className="admin-config-printers-title">
                            Impressora da PLP/NFe (10 x 15cm)
                            <textarea className="print-area" value={ZPL1} onChange={(ev) => setZPL1(ev.target.value)} />
                            <div
                                className="admin-printer-send"
                                onClick={() => {
                                    onlinePrint(2, ZPL1.replace("^XA", "^XA ^PW1200"));
                                }}
                            >
                                Enviar ZPL
                            </div>
                        </div>
                    </div>
                    <div className="admin-config-printers">
                        <div
                            className="admin-printer-test"
                            onClick={() => {
                                onlinePrint(1, etqTestLabel);
                            }}
                        >
                            Testar
                        </div>
                        <div className="admin-config-printers-title">
                            Impressora das Etiquetas (2,5 x 4cm)
                            <textarea className="print-area" value={ZPL2} onChange={(ev) => setZPL2(ev.target.value)} />
                            <div
                                className="admin-printer-send"
                                onClick={() => {
                                    onlinePrint(1, ZPL2);
                                }}
                            >
                                Enviar ZPL
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AdminAistem fullscreen={props.fullscreen} />
            <NavbarAdmin location={useLocation()} user={props.user} setConfigModal={setConfigModal} />
            <div className="content">
                <Outlet />
            </div>
        </>
    );
}
