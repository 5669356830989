import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader/Loader";

import useWebSocket from "react-use-websocket";
import { useContext } from "react";
import { UserContext } from "../App.js";

import Queue from "sync-queue";
import { addNotification } from "../components/Admin/AIstem/AIstem.js";

var queue = new Queue();

const Auth = (props) => {
    const {
        setUser,
        setAdminProducts,
        adminProducts,
        setAdminBoxes,
        setAdminGroups,
        adminGroups,
        setAdminMaterials,
        setAdminUsers,
        adminUsers,
        setAdminOrders,
        adminOrders,
        setAdminPayments,
        setOnlineUsers,
        setMessages,
        setNewUser,
        setStarboxSlots,
        setStarboxTasks,
        setStarboxStatus,
        setStarboxToday,
        setConfig,
        setOrders10Days,
    } = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const ordersRef = useRef();

    useEffect(() => {
        ordersRef.current = adminOrders || [];
    }, [adminOrders]);

    useWebSocket(window.location.protocol === "http:" ? `ws://${window.location.hostname}:8080/wsadmin` : `wss://${window.location.hostname}/wsadmin`, {
        reconnectAttempts: 1000,
        onMessage: (msg) => {
            try {
                let data = JSON.parse(msg.data);

                console.log(data);

                if (data.reloadPage) {
                    window.location.reload();
                }

                if (data.notification) {
                    addNotification(data.notification);
                }

                // if (data.recurrentNotification) {
                //     addNotification(data.recurrentNotification, true);
                //     // setNotificationList((notList) => [
                //     //     ...notList.filter((nl) => data.notifications.map((n) => n.text).includes(nl.text)),
                //     //     ...(data.notifications.filter((n) => !notList.map((a) => a.text).includes(n.text)) || []),
                //     // ]);
                // }

                if (data.user) setUser(data.user);

                if (data.users) setAdminUsers(data.users);

                if (data.adminUser) {
                    let newUsers = [...adminUsers];
                    if (adminUsers.find((u) => u.id === data.adminUser.id)) {
                        newUsers[adminUsers.findIndex((e) => e.id === data.adminUser.id)] = data.adminUser;
                    } else {
                        newUsers.unshift(data.adminUser);
                    }
                    setAdminUsers(newUsers);
                }

                if (data.boxes) setAdminBoxes(data.boxes);

                if (data.materials) setAdminMaterials(data.materials);

                if (data.groups) setAdminGroups(data.groups);
                if (data.group) {
                    if (data.group.destroy) {
                        setAdminGroups([...adminGroups.filter((e) => e.id !== data.product.id)]);
                    } else {
                        setAdminGroups([data.group, ...adminGroups.filter((e) => e.id !== data.group.id)]);
                    }
                }

                if (data.products) setAdminProducts(data.products);
                if (data.product) {
                    if (data.product.destroy) {
                        setAdminProducts([...adminProducts.filter((e) => e.id !== data.product.id)]);
                    } else {
                        let newProducts = [...adminProducts];
                        let index = newProducts.findIndex((o) => o.id === data.product.id);
                        if (index === -1) {
                            setAdminProducts([data.product, ...adminProducts.filter((e) => e.id !== data.product.id)]);
                        } else {
                            newProducts[index] = data.product;
                            setAdminProducts(newProducts);
                        }

                        // setAdminProducts([data.product, ...adminProducts.filter((e) => e.id !== data.product.id)]);
                    }
                }

                if (data.orders) setAdminOrders(data.orders);
                if (data.order) {
                    // if (ordersRef.current?.length)
                    queue.place(async () => {
                        if (data.order.destroy) {
                            await setAdminOrders([...ordersRef?.current?.filter((e) => e.id !== data.order.id)]);
                        } else {
                            // if (
                            //     data.order.status === 0 &&
                            //     !data.order.nfePrinted &&
                            //     !data.order.plpPrinted &&
                            //     data.order.autoPrint &&
                            //     autoPrint &&
                            //     Cookies.get("starbox") !== "false" &&
                            //     !printed.current.includes(data.order.id)
                            // ) {
                            //     printed.current.push(data.order.id);
                            //     await printPLPs([data.order]);
                            //     await printNFes([data.order]);
                            // }

                            let newOrders = [...ordersRef.current];
                            let index = newOrders.findIndex((o) => o.id === data.order.id);
                            if (index === -1) {
                                await setAdminOrders([data.order, ...ordersRef.current.filter((e) => e.id !== data.order.id)]);
                            } else {
                                newOrders[index] = data.order;
                                await setAdminOrders(newOrders);
                            }
                        }
                        setTimeout(() => queue.next(), 100);
                    });
                }

                if (data.payments) {
                    setAdminPayments(data.payments);
                }

                if (data.messages) {
                    setMessages(data.messages);
                }

                if (data.message) {
                    setMessages((messages) => [data.messages, ...messages]);
                }

                if (data.onlineUsers) {
                    let online = [...data.onlineUsers];
                    online.reverse();
                    setOnlineUsers(online);
                }

                if (data.newUser) {
                    setNewUser(data.newUser);
                }

                if (data.starboxSlots) {
                    setStarboxSlots((slots) => data.starboxSlots);
                }

                if (data.starboxTasks) {
                    setStarboxTasks(data.starboxTasks);
                }

                if (data.starboxData) {
                    setStarboxStatus((status) => {
                        return {
                            lastUpdate: Date.now(),
                            x: data.starboxData?.x || status.x,
                            y: data.starboxData?.y || status.y,
                            z: data.starboxData?.z || status.z,
                            status: data.starboxData?.status || status.status,
                        };
                    });
                }

                if (data.starboxToday) {
                    setStarboxToday(data.starboxToday);
                }

                if (data.config) {
                    const config = data.config.map((e) => {
                        return [e.name, e.value];
                    });
                    setConfig(Object.fromEntries(config));
                }

                if (data.orders10Days) {
                    setOrders10Days(data.orders10Days);
                }

                // setAdminProducts(
                //     Object.fromEntries(
                //         data.products.map((e) => [e.id, e])
                //     )
                // );

                if (data.error && data.error === "NOT_LOGGED_IN") {
                    navigate(`/admin/login?redirect=${window.location.pathname + window.location.search}`);
                } else {
                    if (data.loggedIn) {
                        setIsLoading(false);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        onError: (event) => {
            console.error(event);
        },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000,
    });

    // useEffect(() => {
    //     const isAuth = async () => {
    //         try {
    //             const response = await fetch(`/api/users/authorizedadm`, {
    //                 method: "GET",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //             });

    //             const data = await response.json();

    //             // alert(JSON.stringify(data));

    //             setIsAuth(data.id ? true : false);
    //             setIsLoading(false);

    //             if (data.id) {
    //                 props.setUser(data);
    //             }
    //         } catch (err) {
    //             setTimeout(isAuth, 10000);
    //         }
    //     };
    //     isAuth();
    // }, []);
    // useEffect(() => {
    //     setTimeout(() => setIsLoaded(!isLoading && isAuth), 500);
    // }, [isAuth, isLoading]);

    if (!isLoading) return <>{props.children}</>;
    // if (!isLoading && !isAuth) {
    //     return navigate("/login?redirect=" + path);
    // }

    return (
        <div className="auth-loader">
            <Loader />
        </div>
    );
};

export default Auth;
