import React, { useEffect, useState, Fragment, useRef } from "react";
import BarcodeReader from "react-barcode-reader";

import Loader from "../../../components/Loader/Loader";

import "./Orders.css";

import marketplaces from "../../../config/marketplaces.json";

import { useContext } from "react";
import { UserContext } from "../../../App.js";
import CopyDiv from "../../../components/CopyDiv/CopyDiv.js";

import { printPLPs, printNFes } from "./Orders.js";

export default function AdminOrders() {
    const { adminOrders, adminUsers, adminProducts, plpPrinter, etqPrinter, zebraPrint, adminBoxes } = useContext(UserContext);

    const ordersRef = useRef("");

    const statuses = {
        0: { name: "Aguardando", color: "#ae2f2f" },
        1: { name: "Solicitado", color: "#6200ff" },
        2: { name: "Pronto", color: "#0080ff" },
        3: { name: "A gravar", color: "#ff970f" },
        4: { name: "Gravado", color: "#0080ff" },
        9: { name: "Embalado", color: "rgb(10 119 10)" },
    };

    const [orders, setOrders] = useState(null);

    const [hovered, setHovered] = useState(false);

    const [search, setSearch] = useState(undefined);
    const [ordersSearch, setOrdersSearch] = useState([]);

    const [cancelModal, setCancelModal] = useState(false);
    const [cancelReason, setCancelReason] = useState("");
    const [modalOrder, setModalOrder] = useState({});

    const [modalPlp, setModalPlp] = useState(false);
    const [modalNfe, setModalNfe] = useState(false);
    const [plp, setPlp] = useState(false);

    const [modalOrderObj, setModalOrderObj] = useState({});

    const getPlpImage = (plpUrl) => {
        return new Promise(async (resolve, reject) => {
            if (!plpUrl) reject();

            const plpResponse = await fetch(`/uploads/orders/${plpUrl}`);
            let plpData = await plpResponse.text();
            if (!plpData) reject();

            const response = await fetch("https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/", {
                method: "POST",
                body: plpData,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            let blob = await response.blob();

            let text = await blob.text();

            if (text.includes("ERROR")) resolve("/img/plperror.png");

            blobToBase64(blob, function (image) {
                resolve(`data:image/png;base64,${image}`);
            });
        });
    };

    var blobToBase64 = function (blob, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(",")[1];
            callback(base64);
        };
        reader.readAsDataURL(blob);
    };

    useEffect(() => {
        updateSearch();
    }, [orders, search]);

    const updateSearch = async () => {
        if (!orders) return;

        if (search) {
            let newOrders = orders.map((e) => {
                return {
                    ...e,
                    user: adminUsers.find((u) => u.id === e.userId),
                };
            });
            newOrders = newOrders.filter((e) => {
                let src = search.toLowerCase();
                return (
                    src.includes(e.id) ||
                    `${e.id}`.includes(src) ||
                    Object.values(e.order_products).some((en) =>
                        adminProducts
                            .find((ap) => ap.id === en.productId)
                            ?.sku?.toLowerCase()
                            .includes(src)
                    ) ||
                    Object.values(e.order_products).some(
                        (en) =>
                            src.includes(adminProducts.find((ap) => ap.id === en.productId)?.sku?.toLowerCase()) ||
                            marketplaces[e.marketplace]?.name.toLowerCase().includes(src) ||
                            adminUsers
                                .find((u) => u.id === e.userId)
                                ?.email.toLowerCase()
                                .includes(src)
                    )
                );
            });

            setOrdersSearch(newOrders);
        } else {
            let newOrders = orders.map((e) => {
                return {
                    ...e,
                    user: adminUsers.find((u) => u.id === e.userId),
                };
            });

            setOrdersSearch(newOrders);
        }
    };

    useEffect(() => {
        ordersRef.current = orders || [];
    }, [orders]);

    function getEtq(id, sku, name) {
        return `^XA
            ^PW319
            ^FWN
            ^LH0,5
            ^FO245,120^GFA,170,170,5,,::003C0018,007E007E,00FF00FE,00FF00FF,:00FE00FE,007E007E,003C003C,,I07FFE,003JFE,00LF8,03FF80FFE,07F8001FF,0FEJ07F81FCJ03F81F8J01FC1F8K0FC:1F8J01FC1FCJ03F80FEJ07F807F8I0FF,03FF00FFE,00LF8,003JFE,I07IF,,:::^FS
            
            ^BY2
            ^FO35,8 ^BCN,100,N ^FD${`${id}`.padStart(8, "0")}^FS
            ^FO35,125 ^A0,30 ^FD${sku}^FS
            ^FO35,165 ^A0,20 ^FD${(name || "Produto").normalize("NFD").replace(/[\u0300-\u036f]/g, "")}^FS
            ^XZ`;
    }

    async function setOrderStatus(order, status) {
        fetch("/api/orders/update", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ids: order.id,
                data: {
                    status: status || 1,
                },
            }),
        })
            .then(() => console.log("ok"))
            .catch(() => {
                setTimeout(() => {
                    setOrderStatus(order, status);
                }, 1000);
            });
    }

    useEffect(() => {
        setOrders(
            adminOrders
                .filter((order) => order.status === 5)
                ?.slice(0, 6500)
                .reverse()
        );
    }, [adminOrders]);

    let ordersSorted = Object.entries(ordersSearch);
    ordersSorted.sort((a, b) => {
        return marketplaces[a[1].marketplace]?.adminOrder - marketplaces[b[1].marketplace]?.adminOrder;
    });

    return (
        <>
            <BarcodeReader
                onScan={(data) => {
                    const theOrder = ordersRef.current?.find((o) => Number(o.id) === Number(data));

                    if (!theOrder) return;
                    setOrderStatus(theOrder);
                }}
            />
            <div className={`order-modal-admin-bg-2 ${modalNfe ? "shown" : ""}`} onClick={() => setModalNfe(false)}>
                <div className="order-modal-admin order-modal-plp order-modal-nfe" onClick={(ev) => ev.stopPropagation()}>
                    <iframe
                        src={
                            `${window.location.protocol}//${
                                window.location.hostname == "localhost" ? `${window.location.hostname}:5000` : window.location.hostname
                            }/uploads/orders/` + modalOrderObj.nfe
                        }
                        type="application/pdf"
                        width="400px"
                        height="550px"
                    />
                </div>
            </div>

            <div
                className={`order-modal-admin-bg-2 ${modalPlp ? "shown" : ""}`}
                onClick={() => {
                    setModalPlp(false);
                    setPlp(null);
                }}
            >
                <div className="order-modal-admin order-modal-plp" onClick={(ev) => ev.stopPropagation()}>
                    <div className={`plp-loading ${plp ? "hidden" : ""}`}>
                        <Loader />
                    </div>
                    <img className="plp-img" src={plp || ""} />
                </div>
            </div>
            <div className={`admin-cancel-modal-bg ${cancelModal ? "shown" : ""}`} onClick={() => setCancelModal(false)}>
                <div className="admin-cancel-modal" onClick={(ev) => ev.stopPropagation()}>
                    Qual é o motivo do cancelamento?
                    <textarea onChange={(ev) => setCancelReason(ev.target.value)} value={cancelReason} />
                    <div
                        className="admin-cancel-modal-btn"
                        onClick={() => {
                            if (!cancelReason) return alert("Preencha o motivo");

                            fetch("/api/orders/cancel", {
                                method: "POST",
                                headers: { "Content-Type": "application/json" },
                                body: JSON.stringify({
                                    orderId: modalOrder.id,
                                    reason: cancelReason,
                                }),
                            });

                            let newOrders = ordersRef.current;
                            newOrders = newOrders.filter((e) => e.id !== modalOrder.id);
                            setOrders(newOrders);

                            setCancelReason("");
                            setCancelModal(false);
                        }}
                    >
                        Confirmar cancelamento
                    </div>
                </div>
            </div>
            <div className="admin-orders-container">
                <div className="admin-order-container">
                    <Loader className={`${adminOrders.length ? "hidden" : ""}`} />
                    <div className="admin-order-search" style={{ zIndex: hovered ? 9999 : 99999 }}>
                        <div className="admin-order-search-container">
                            <div className="admin-order-search-input">
                                <input
                                    placeholder="Pesquise por nº do pedido, SKU ou marketplace"
                                    value={search || ""}
                                    onChange={(ev) => {
                                        setSearch(ev.target.value);
                                    }}
                                />
                                <div className="admin-search-qtty">
                                    <div>{Object.keys(ordersSearch).length} Pedidos</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {Object.keys(ordersSearch).length !== 0
                        ? ordersSorted.map(([key, order], i) => {
                              let marketplace;
                              if (!ordersSorted[i - 1] || (ordersSorted[i - 1] && ordersSorted[i - 1][1].marketplace !== order.marketplace)) {
                                  marketplace = marketplaces[order.marketplace];
                              }

                              if (!order) return <></>;

                              let clickTimeout;

                              return (
                                  <Fragment key={order.id}>
                                      {marketplace ? (
                                          <>
                                              {i !== 0 ? (
                                                  <div
                                                      className="order-marketplace-ending"
                                                      style={{
                                                          borderColor:
                                                              marketplace && marketplaces[ordersSorted[i - 1][1].marketplace]
                                                                  ? marketplaces[ordersSorted[i - 1][1].marketplace].color
                                                                  : "#fff",
                                                      }}
                                                  ></div>
                                              ) : (
                                                  ""
                                              )}
                                              <div
                                                  className="order-marketplace-separator"
                                                  style={{
                                                      borderColor:
                                                          marketplace && marketplaces[order.marketplace] ? marketplaces[order.marketplace].color : "#fff",
                                                  }}
                                              >
                                                  <img
                                                      src={
                                                          marketplaces[order.marketplace]?.img || marketplaces[marketplaces[order?.marketplace]?.parentId]?.img
                                                      }
                                                  />
                                                  {!marketplaces[order.marketplace]?.img && marketplaces[order?.marketplace]?.parentId ? (
                                                      <div className="sub-marketplace-name">{marketplaces[order.marketplace]?.name}</div>
                                                  ) : (
                                                      <></>
                                                  )}
                                              </div>
                                          </>
                                      ) : (
                                          ""
                                      )}
                                      <div
                                          className={`admin-order admin-order-static2`}
                                          key={order.id}
                                          style={{
                                              animationDelay: `${i * 50}ms`,
                                              borderColor:
                                                  order.marketplace !== null && marketplaces[order.marketplace]
                                                      ? marketplaces[order.marketplace].color
                                                      : "#fff",
                                          }}
                                      >
                                          <div className="admin-order-menu">
                                              <CopyDiv
                                                  className="admin-order-number"
                                                  style={{
                                                      backgroundColor: statuses[9].color,
                                                  }}
                                                  value={order.id}
                                              >
                                                  {`${order.id}`.padStart(6, 0)}
                                              </CopyDiv>
                                              <div className="admin-order-btns">
                                                  <div
                                                      className="printed"
                                                      onClick={async () => {
                                                          clearTimeout(clickTimeout);
                                                          clickTimeout = setTimeout(() => {
                                                              if (!order.plp) return alert("PLP não encontrada");
                                                              setModalPlp(true);

                                                              getPlpImage(order.plp).then((img) => setPlp(img));
                                                          }, 200);
                                                      }}
                                                      onDoubleClick={() => {
                                                          clearTimeout(clickTimeout);
                                                          printPLPs([order], adminProducts);
                                                      }}
                                                  >
                                                      PLP
                                                  </div>
                                                  <div
                                                      className="printed"
                                                      onDoubleClick={() => {
                                                          clearTimeout(clickTimeout);
                                                          printNFes([order]);
                                                      }}
                                                      onClick={() => {
                                                          clearTimeout(clickTimeout);
                                                          clickTimeout = setTimeout(() => {
                                                              if (!order.nfe) return;
                                                              setModalOrderObj(order);
                                                              setModalNfe(true);
                                                          }, 200);
                                                      }}
                                                  >
                                                      NFe
                                                  </div>

                                                  <div
                                                      className=""
                                                      onClick={() => {
                                                          setModalOrder(order);
                                                          setCancelModal(true);
                                                      }}
                                                  >
                                                      Cancelar
                                                  </div>
                                                  {/* {order?.data?.shipment?.logistic_type} */}
                                              </div>
                                              <div className="admin-order-marketplace-img">
                                                  {" "}
                                                  <img
                                                      src={marketplaces[order.marketplace]?.img || marketplaces[marketplaces[order.marketplace]?.parentId]?.img}
                                                      alt={marketplaces[order.marketplace]?.name}
                                                  />
                                                  {marketplaces[order.marketplace]?.parentId && !marketplaces[order.marketplace]?.img ? (
                                                      <span className="admin-marketplace-sub">{marketplaces[order.marketplace]?.name}</span>
                                                  ) : (
                                                      ""
                                                  )}
                                              </div>
                                              {order.marketplace !== 82 ? (
                                                  order.idBling ? (
                                                      <img className="order-bling-icon" src="/img/orders/bling.svg" />
                                                  ) : order.meliId || order.magaluId ? (
                                                      <div className="order-bling-icon">AI-stem</div>
                                                  ) : (
                                                      <div className="order-bling-icon manual">Manual</div>
                                                  )
                                              ) : (
                                                  <></>
                                              )}
                                              {order.autoPrint ? <img className="order-starbox-logo" src="/img/starbox.png" /> : <></>}
                                              <div className="admin-order-username">
                                                  {order.user?.name}
                                                  <div className="admin-order-user">
                                                      <img src="/img/einstein-user-logo.svg" className="admin-order-user-photo" alt="Foto" />
                                                      <div className="admin-order-user-email-container">
                                                          <div className="admin-order-user-email">{order.user?.email}</div>
                                                          <a
                                                              target="_blank"
                                                              rel="noreferrer"
                                                              href={`https://wa.me/55${order?.user?.phone}`}
                                                              className="admin-order-user-whatsapp"
                                                          >
                                                              ({order.user?.phone?.slice(0, 2)}) {order.user?.phone?.slice(2, -1)}
                                                          </a>
                                                      </div>
                                                  </div>
                                              </div>

                                              <div
                                                  className="admin-order-dates"
                                                  onClick={(ev) => {
                                                      setOrderStatus(order);
                                                  }}
                                              >
                                                  <div className="admin-order-date">
                                                      Confirmado
                                                      <div className="admin-order-date-number">
                                                          {new Date(order?.statuses?.confirmed || order?.createdAt)
                                                              .toLocaleString("pt-BR")
                                                              .slice(0, -3)
                                                              .replace(",", "")}
                                                      </div>
                                                  </div>
                                                  {order?.statuses?.packed ? (
                                                      <div className="admin-order-date packed">
                                                          Embalado
                                                          <div className="admin-order-date-number">
                                                              {new Date(order?.statuses?.packed || Date.now())
                                                                  .toLocaleString("pt-BR")
                                                                  .slice(0, -3)
                                                                  .replace(",", "")}
                                                          </div>
                                                      </div>
                                                  ) : (
                                                      <></>
                                                  )}
                                                  {order?.statuses?.collected ? (
                                                      <div className="admin-order-date collected">
                                                          Coletado
                                                          <div className="admin-order-date-number">
                                                              {new Date(order?.statuses?.collected || Date.now())
                                                                  .toLocaleString("pt-BR")
                                                                  .slice(0, -3)
                                                                  .replace(",", "")}
                                                          </div>
                                                      </div>
                                                  ) : (
                                                      <></>
                                                  )}
                                                  {order?.statuses?.cancelled ? (
                                                      <div className="admin-order-date cancelled">
                                                          Cancelado
                                                          <div className="admin-order-date-number">
                                                              {new Date(order?.statuses?.cancelled || Date.now())
                                                                  .toLocaleString("pt-BR")
                                                                  .slice(0, -3)
                                                                  .replace(",", "")}
                                                          </div>
                                                      </div>
                                                  ) : (
                                                      <></>
                                                  )}
                                              </div>
                                          </div>
                                          <div className="admin-order-products">
                                              {order?.order_products &&
                                                  Object.keys(order.order_products).map((productKey) => {
                                                      const orderProduct = order.order_products[productKey];

                                                      orderProduct.product = adminProducts.find((p) => orderProduct.productId === p.id);

                                                      let clickTimeout;
                                                      return (
                                                          <Fragment key={orderProduct.id}>
                                                              <div
                                                                  className={`admin-order-product`}
                                                                  style={{
                                                                      backgroundColor: statuses[9]?.color,
                                                                      borderColor: statuses[9]?.color,
                                                                  }}
                                                                  onMouseEnter={() => setHovered(true)}
                                                                  onMouseLeave={() => setHovered(false)}
                                                                  onDoubleClick={(ev) => {
                                                                      clearTimeout(clickTimeout);
                                                                      zebraPrint(
                                                                          etqPrinter,
                                                                          getEtq(orderProduct.product.id, orderProduct.product.sku, orderProduct.product.name)
                                                                      );
                                                                  }}
                                                                  onClick={() => {
                                                                      clearTimeout(clickTimeout);
                                                                      clickTimeout = setTimeout(() => {
                                                                          //setProductStatus(9, orderProduct.id, order.id)
                                                                      }, 200);
                                                                  }}
                                                                  key={orderProduct.id}
                                                              >
                                                                  <img src={`/uploads/thumbs/${orderProduct.product.photos[0]}`} alt={orderProduct.sku} />
                                                                  <div className="admin-order-sku">{orderProduct.product.sku}</div>
                                                                  <img
                                                                      className="expand"
                                                                      src={`/uploads/thumbs/${orderProduct.product.photos[0]}`}
                                                                      alt={orderProduct.sku}
                                                                  />
                                                              </div>
                                                              {(Array.isArray(orderProduct.options) ? orderProduct.options : []).map((op, index) => {
                                                                  //   let option = options[op.name];
                                                                  let name, image;

                                                                  switch (op.name) {
                                                                      case "box":
                                                                          let box = adminBoxes.find((b) => b.id === orderProduct.product.box);
                                                                          if (box) {
                                                                              name = box.name || "Caixa";
                                                                              image = `/uploads/internal/${box.photo}`;
                                                                          }
                                                                          break;
                                                                      case "gift":
                                                                          name = "Presente";
                                                                          image = "/img/presente3.png";
                                                                          break;
                                                                      case "name":
                                                                          name = op.value;
                                                                          image = "/img/burn.png";
                                                                          break;
                                                                      default:
                                                                          name = op.name;
                                                                          break;
                                                                  }
                                                                  return (
                                                                      <div
                                                                          className="admin-order-product"
                                                                          style={{
                                                                              backgroundColor: statuses[9].color,
                                                                          }}
                                                                          key={index}
                                                                      >
                                                                          {image ? (
                                                                              <>
                                                                                  <img src={image} alt={name} />
                                                                                  <img className="expand" src={image} alt={name} />
                                                                              </>
                                                                          ) : (
                                                                              ""
                                                                          )}
                                                                          <div className="admin-order-sku">{name}</div>
                                                                      </div>
                                                                  );
                                                              })}
                                                          </Fragment>
                                                      );
                                                  })}
                                          </div>
                                      </div>
                                  </Fragment>
                              );
                          })
                        : ""}
                    {ordersSorted.length ? (
                        <div
                            className="order-marketplace-ending"
                            style={{
                                borderColor: marketplaces[ordersSorted[ordersSorted.length - 1][1].marketplace]
                                    ? marketplaces[ordersSorted[ordersSorted.length - 1][1].marketplace].color
                                    : "#fff",
                            }}
                        ></div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
}
