import { useState, useEffect, forwardRef } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import "./Banned.css";

export default function Banned(props) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.user.banned) {
            navigate("/");
        }
    }, [props.user]);

    return (
        <>
            <div className="admin-login-page">
                <div className="entrance-payment">
                    Você foi banido da plataforma.
                    <br />
                    {/* Para entrar em contato conosco, ! */}
                    {/* <a
                        href={`${window.location.protocol}//${
                            window.location.hostname == "localhost"
                                ? `${window.location.hostname}:5000`
                                : window.location.hostname
                        }/api/mercadopago/paymentlink`}
                        target="_blank"
                        className="entrance-payment-btn"
                    >
                        Realizar pagamento
                    </a> */}
                    <a href="/logout" className="entrance-payment-leave">
                        Sair
                    </a>
                </div>
            </div>
        </>
    );
}
